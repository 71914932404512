import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Slider from './Slider';
import './filter.css';

const SearchFilters = ({ filters, setFilters, nationalities }) => {
  // const [selectAll, setSelectAll] = useState(false);

  const handleFieldChange = (event, filterName) => {
    const filterValue = event.target.value;

    const updatedFilters = { ...filters };

    if (!(filterName in updatedFilters)) {
      updatedFilters[filterName] = [];
    }
    if (updatedFilters[filterName].includes(filterValue)) {
      const index = updatedFilters[filterName].indexOf(filterValue);
      updatedFilters[filterName].splice(index, 1);
    } else {
      updatedFilters[filterName].push(filterValue);
    }

    // const allSelected = updatedFilters.length === filters.length;
    // setSelectAll(allSelected);
    setFilters(updatedFilters);

    // console.log('filters in modal ' + JSON.stringify(filters));
  };

  //   const handleSelectAllChange = (event) => {
  //     const isChecked = event.target.checked;
  //     setSelectAll(isChecked);

  //     if (isChecked) {
  //       setFilters([...filters]);
  //     } else {
  //       setFilters([]);
  //     }
  //   };

  return (
    <div>
      <Accordion className='pb-2 m-2' alwaysOpen>
        <div className='mx-3 d-flex justify-content-between align-items-center px-1 py-3'>
          <label htmlFor='38' style={{ cursor: 'pointer' }}>
            Entrepreneurial intention
          </label>
          <input
            className='form-check-input m-0 p-0  border border-dark'
            style={{ cursor: 'pointer' }}
            type='checkbox'
            id='38'
            value='1'
            onChange={(e) => handleFieldChange(e, 'entrepreneurial_intention')}
            checked={filters['entrepreneurial_intention'].includes('1')}
          />
        </div>
        <Accordion.Item eventKey='0' className='border-top'>
          <Accordion.Header className=''>Entrepreneurial motivation score</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <Slider
                  min={0}
                  max={5}
                  step={1}
                  filters={filters}
                  setFilters={setFilters}
                  forId='display1'
                />
                <div id='display1' className='mt-1 text-muted'></div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='3' className=''>
          <Accordion.Header className=''>Idea type</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='7'
                    value='STARTUP'
                    onChange={(e) => handleFieldChange(e, 'idea_type__in')}
                    checked={filters['idea_type__in'].includes('STARTUP')}
                  />
                  <label htmlFor='7'>Startup</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='88'
                    value='GENERAL'
                    onChange={(e) => handleFieldChange(e, 'idea_type__in')}
                    checked={filters['idea_type__in'].includes('GENERAL')}
                  />
                  <label htmlFor='88'>General</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='9'
                    value='UNSURE'
                    onChange={(e) => handleFieldChange(e, 'idea_type__in')}
                    checked={filters['idea_type__in'].includes('UNSURE')}
                  />
                  <label htmlFor='9'>Unsure</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='10'
                    value='NO_IDEA'
                    onChange={(e) => handleFieldChange(e, 'idea_type__in')}
                    checked={filters['idea_type__in'].includes('NO_IDEA')}
                  />
                  <label htmlFor='10'>No idea</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <div className='border-bottom'>
          <div className='mx-3 d-flex justify-content-between align-items-center px-1 py-3'>
            <label htmlFor='47' style={{ cursor: 'pointer' }}>
              Special help
            </label>
            <input
              className='form-check-input m-0 p-0 border border-dark'
              style={{ cursor: 'pointer' }}
              type='checkbox'
              id='47'
              value='0'
              onChange={(e) => handleFieldChange(e, 'idea_help__isnull')}
              checked={filters['idea_help__isnull'].includes('0')}
            />
          </div>
        </div>
        <Accordion.Item eventKey='1' className=''>
          <Accordion.Header className=''> First timer </Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='1'
                    value='1'
                    onChange={(e) => handleFieldChange(e, 'first_hackathon__in')}
                    checked={filters['first_hackathon__in'].includes('1')}
                  />
                  <label htmlFor='1'>Yes</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='45'
                    value='0'
                    onChange={(e) => handleFieldChange(e, 'first_hackathon__in')}
                    checked={filters['first_hackathon__in'].includes('0')}
                  />
                  <label htmlFor='45'>No</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <div className='border-bottom'>
          <div className='mx-3 d-flex justify-content-between align-items-center px-1 py-3'>
            <label htmlFor='46' style={{ cursor: 'pointer' }}>
              Expectations
            </label>
            <input
              className='form-check-input m-0 p-0 border border-dark'
              style={{ cursor: 'pointer' }}
              type='checkbox'
              id='46'
              value='0'
              onChange={(e) => handleFieldChange(e, 'expectations__isnull')}
              checked={filters['expectations__isnull'].includes('0')}
            />
          </div>
        </div>
        <Accordion.Item eventKey='2'>
          <Accordion.Header className=''>Skills</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col col={6}>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='2'
                    value='PROGRAMMING'
                    onChange={(e) => handleFieldChange(e, 'skills__in')}
                    checked={filters['skills__in'].includes('PROGRAMMING')}
                  />
                  <label htmlFor='2'>Programming</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    value='DESIGN'
                    id='3'
                    onChange={(e) => handleFieldChange(e, 'skills__in')}
                    checked={filters['skills__in'].includes('DESIGN')}
                  />
                  <label htmlFor='3'>Design</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='4'
                    value='MARKETING'
                    onChange={(e) => handleFieldChange(e, 'skills__in')}
                    checked={filters['skills__in'].includes('MARKETING')}
                  />
                  <label htmlFor='4'>Marketing</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='5'
                    value='BUSINESS'
                    onChange={(e) => handleFieldChange(e, 'skills__in')}
                    checked={filters['skills__in'].includes('BUSINESS')}
                  />
                  <label htmlFor='5'>Business</label>
                </div>
              </Col>
              <Col col={6}>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='6'
                    value='OTHER'
                    onChange={(e) => handleFieldChange(e, 'skills__in')}
                    checked={filters['skills__in'].includes('OTHER')}
                  />
                  <label htmlFor='6'>Other</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='4' className=''>
          <Accordion.Header className=''> Team status</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='11'
                    value='TEAM_FULL'
                    onChange={(e) => handleFieldChange(e, 'team_status__in')}
                    checked={filters['team_status__in'].includes('TEAM_FULL')}
                  />
                  <label htmlFor='11'>Team full</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='12'
                    value='TEAM_NOT_FULL'
                    onChange={(e) => handleFieldChange(e, 'team_status__in')}
                    checked={filters['team_status__in'].includes('TEAM_NOT_FULL')}
                  />
                  <label htmlFor='12'>Team not full</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='13'
                    value='NO_TEAM'
                    onChange={(e) => handleFieldChange(e, 'team_status__in')}
                    checked={filters['team_status__in'].includes('NO_TEAM')}
                  />
                  <label htmlFor='13'>No team</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='5' className=''>
          <Accordion.Header className=''> Roles needed</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col col={6}>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='14'
                    value='PROGRAMMING'
                    onChange={(e) => handleFieldChange(e, 'team_roles_missing__in')}
                    checked={filters['team_roles_missing__in'].includes('PROGRAMMING')}
                  />
                  <label htmlFor='14'>Programming</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='15'
                    value='DESIGN'
                    onChange={(e) => handleFieldChange(e, 'team_roles_missing__in')}
                    checked={filters['team_roles_missing__in'].includes('DESIGN')}
                  />
                  <label htmlFor='15'>Design</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='16'
                    value='MARKETING'
                    onChange={(e) => handleFieldChange(e, 'team_roles_missing__in')}
                    checked={filters['team_roles_missing__in'].includes('MARKETING')}
                  />
                  <label htmlFor='16'>Marketing</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='17'
                    value='BUSINESS'
                    onChange={(e) => handleFieldChange(e, 'team_roles_missing__in')}
                    checked={filters['team_roles_missing__in'].includes('BUSINESS')}
                  />
                  <label htmlFor='17'>Business</label>
                </div>
              </Col>
              <Col col={6}>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='18'
                    value='OTHER'
                    onChange={(e) => handleFieldChange(e, 'team_roles_missing__in')}
                    checked={filters['team_roles_missing__in'].includes('OTHER')}
                  />
                  <label htmlFor='18'>Other</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='6' className=''>
          <Accordion.Header className=''> Age</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col col={6}>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='19'
                    value='AGE_18_24'
                    onChange={(e) => handleFieldChange(e, 'age__in')}
                    checked={filters['age__in'].includes('AGE_18_24')}
                  />
                  <label htmlFor='19'>18 - 24</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='20'
                    value='AGE_25_34'
                    onChange={(e) => handleFieldChange(e, 'age__in')}
                    checked={filters['age__in'].includes('AGE_25_34')}
                  />
                  <label htmlFor='20'>25 - 34</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='21'
                    value='AGE_35_44'
                    onChange={(e) => handleFieldChange(e, 'age__in')}
                    checked={filters['age__in'].includes('AGE_35_44')}
                  />
                  <label htmlFor='21'>35 - 44</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='22'
                    value='AGE_45_54'
                    onChange={(e) => handleFieldChange(e, 'age__in')}
                    checked={filters['age__in'].includes('AGE_45_54')}
                  />
                  <label htmlFor='22'>45 - 54</label>
                </div>
              </Col>
              <Col col={6}>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='23'
                    value='AGE_55_64'
                    onChange={(e) => handleFieldChange(e, 'age__in')}
                    checked={filters['age__in'].includes('AGE_55_64')}
                  />
                  <label htmlFor='23'>55 - 64</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='24'
                    value='AGE_65_74'
                    onChange={(e) => handleFieldChange(e, 'age__in')}
                    checked={filters['age__in'].includes('AGE_65_74')}
                  />
                  <label htmlFor='24'>65 - 74</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='25'
                    value='AGE_75_UP'
                    onChange={(e) => handleFieldChange(e, 'age__in')}
                    checked={filters['age__in'].includes('AGE_75_UP')}
                  />
                  <label htmlFor='25'>75+</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='7' className=''>
          <Accordion.Header className=''> Gender</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='26'
                    value='M'
                    onChange={(e) => handleFieldChange(e, 'gender__in')}
                    checked={filters['gender__in'].includes('M')}
                  />
                  <label htmlFor='26'>Male</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='27'
                    value='F'
                    onChange={(e) => handleFieldChange(e, 'gender__in')}
                    checked={filters['gender__in'].includes('F')}
                  />
                  <label htmlFor='27'>Female</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='28'
                    value='NB'
                    onChange={(e) => handleFieldChange(e, 'gender__in')}
                    checked={filters['gender__in'].includes('NB')}
                  />
                  <label htmlFor='28'>Non-binary</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='29'
                    value='PNTS'
                    onChange={(e) => handleFieldChange(e, 'gender__in')}
                    checked={filters['gender__in'].includes('PNTS')}
                  />
                  <label htmlFor='29'>Prefer not to say</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='11' className=''>
          <Accordion.Header className=''> Nationality</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                {!nationalities.length && (
                  <p className='text-muted m-1'>No nationalities to filter</p>
                )}
                {nationalities?.map((nationality, i) => {
                  return (
                    <div className='field' key={i}>
                      <input
                        className='form-check-input m-0 p-0'
                        type='checkbox'
                        id={'48' + i}
                        value={nationality}
                        onChange={(e) => handleFieldChange(e, 'nationality__in')}
                        checked={filters['nationality__in'].includes(nationality)}
                      />
                      <label htmlFor={'48' + i}>{nationality}</label>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='8' className=''>
          <Accordion.Header className=''> Meal preference</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='30'
                    value='ME'
                    onChange={(e) => handleFieldChange(e, 'meal_preference__in')}
                    checked={filters['meal_preference__in'].includes('ME')}
                  />
                  <label htmlFor='30'>Meat eater</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='31'
                    value='VG'
                    onChange={(e) => handleFieldChange(e, 'meal_preference__in')}
                    checked={filters['meal_preference__in'].includes('VG')}
                  />
                  <label htmlFor='31'>Vegeterian</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='32'
                    value='VE'
                    onChange={(e) => handleFieldChange(e, 'meal_preference__in')}
                    checked={filters['meal_preference__in'].includes('VE')}
                  />
                  <label htmlFor='32'>Vegan</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='9' className=''>
          <Accordion.Header className=''> Education</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='33'
                    value='HS'
                    onChange={(e) => handleFieldChange(e, 'education__in')}
                    checked={filters['education__in'].includes('HS')}
                  />
                  <label htmlFor='33'>High school</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='34'
                    value='BA'
                    onChange={(e) => handleFieldChange(e, 'education__in')}
                    checked={filters['education__in'].includes('BA')}
                  />
                  <label htmlFor='34'>Bachelors</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='35'
                    value='MS'
                    onChange={(e) => handleFieldChange(e, 'education__in')}
                    checked={filters['education__in'].includes('MS')}
                  />
                  <label htmlFor='35'>Masters</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='36'
                    value='DOC'
                    onChange={(e) => handleFieldChange(e, 'education__in')}
                    checked={filters['education__in'].includes('DOC')}
                  />
                  <label htmlFor='36'>Doctorate</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='37'
                    value='PNTS'
                    onChange={(e) => handleFieldChange(e, 'education__in')}
                    checked={filters['education__in'].includes('PNTS')}
                  />
                  <label htmlFor='37'>Prefer not to say</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='12' className=''>
          <Accordion.Header className=''> Employment</Accordion.Header>
          <Accordion.Body className='px-2'>
            <Row>
              <Col>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='40'
                    value='CEMP'
                    onChange={(e) => handleFieldChange(e, 'employment__in')}
                    checked={filters['employment__in'].includes('CEMP')}
                  />
                  <label htmlFor='40'>Company employee</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='41'
                    value='SEMP'
                    onChange={(e) => handleFieldChange(e, 'employment__in')}
                    checked={filters['employment__in'].includes('SEMP')}
                  />
                  <label htmlFor='41'>Self-employed</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='42'
                    value='UEMP'
                    onChange={(e) => handleFieldChange(e, 'employment__in')}
                    checked={filters['employment__in'].includes('UEMP')}
                  />
                  <label htmlFor='42'>Unemployed</label>
                </div>
                <div className='field'>
                  <input
                    className='form-check-input m-0 p-0'
                    type='checkbox'
                    id='43'
                    value='PNTS'
                    onChange={(e) => handleFieldChange(e, 'employment__in')}
                    checked={filters['employment__in'].includes('PNTS')}
                  />
                  <label htmlFor='43'>Prefer not to say</label>
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SearchFilters;
