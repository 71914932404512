import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const CheckEmail = () => {
  return (
    <div className='text-center'>
      <FontAwesomeIcon icon={faCircleCheck} className='text-success fs-1 mb-3' />
      <div>
        <h5 className='fw-bold'>Check your email</h5>
        <p className='text-muted'>We have sent you instructions on how to reset your password.</p>
      </div>
    </div>
  );
};

export default CheckEmail;
