import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepFour from './steps/StepFour';
import StepFive from './steps/StepFive';

const GuidelineModal = ({ show, setShow, step, setStep }) => {
  const nextStep = () => {
    if (step === 5) {
      setStep(1);
    } else {
      setStep(step + 1);
    }
  };
  const prevStep = () => {
    if (step === 1) {
      setStep(5);
    } else {
      setStep(step - 1);
    }
  };

  const renderModalContent = () => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo />;
      case 3:
        return <StepThree />;
      case 4:
        return <StepFour />;
      case 5:
        return <StepFive />;

      default:
        return null;
    }
  };
  return (
    <Modal show={show} className='py-5 w-100 modal-lg' scrollable onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Guidelines</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4 '>{renderModalContent()}</Modal.Body>
      <Row className='mt-1 mb-4 px-4'>
        <Col className='d-flex gap-2'>
          <Button variant='outline-info' className='w-50' onClick={prevStep}>
            Previous
          </Button>
          <Button className='w-50 btn-prim' onClick={nextStep}>
            Next ({step}/5)
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default GuidelineModal;
