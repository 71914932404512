import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import formatFilterOption from '../filters/formatFilterOption';
import './SelectedFiltersDisplay.css';

const SelectedFiltersDisplay = ({
  filters,
  setFilters,
  removeFilter,
  getKeyByValue,
  filterNames /*handleClearFilter*/,
}) => {
  //console.log('Filters in SelectedFiltersDisplay:', filters);

  let index = 0;

  /*const mapBooleanFilterValues = (filterValues) => {
    if (filterValues.length === 1 && (filterValues[0] === '0' || filterValues[0] === '1')) {
        return filterValues.map((value) => (value === '1' ? 'Yes' : 'No'));
    } else if (filterValues.length > 1) {
        return filterValues.map((value) => value === '1' ? 'Yes' : (value === '0' ? 'No' : value));
    }
    return filterValues;
  };*/

  const formatFilterValue = (value) => {
    if (typeof value === 'string') {
      return value.charAt(0) + value.slice(1).toLowerCase();
    }
    return value;
  };

  const removeAllFilters = (filterKey) => {
    filters[filterKey].forEach((filterValue) => {
      removeFilter(filterKey, filterValue);
    });

    const radioBtns = document.querySelectorAll('input[type=radio]');
    radioBtns.forEach((radio) => {
      if (radio.checked === true) {
        radio.checked = false;
      }
    });

    // Clear the selection for the specified filter key
    setFilters({ ...filters, [filterKey]: [] });
  };

  const capitalizeFirstLetter = (inputString) => {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };

  const formatFilterName = (rawName) => {
    // Asendab "_" tühikuga ja eemaldab "_isnull"
    const replacedName = rawName
      .replace('first_hackathon__in', 'First timer:')
      .replace('employment__in', 'Employment:')
      .replace('education__in', 'Education:')
      .replace('nationality__in', 'Nationality:')
      .replace('gender__in', 'Gender:')
      .replace('age__in', 'Aged:')
      .replace('skills__in', 'Skills:')
      .replace('motivation_score__gte', 'Motivation score ≥')
      .replace('motivation_score__lte', 'Motivation score ≤')
      .replace('idea_type__in', 'Idea type:')
      .replace('team_status__in', 'Team status:')
      .replace('team_roles_missing__in', 'Team roles missing:')
      .replace('meal_preference__in', 'Meal preference:')
      .replace('idea_help__isnull', 'Special help')
      .replace('first_hackathon__in', 'First timer:')
      .replace('expectations__isnull', 'Expectations')
      .replace(/_/g, ' '); // Replace underscores with spaces

    return capitalizeFirstLetter(replacedName);
  };

  return (
    <div>
      {Object.keys(filters).some((key) => filters[key].length > 0) && (
        <div className='ms-2 gap-2 d-flex flex-wrap align-items-center'>
          {/* Additional section for displaying the "order_by" filter */}
          {filters['order_by'].length > 0 && (
            <div className='d-flex align-items-center mb-2'>
              <h6 className='text-muted my-auto me-2'>Sorting Method:</h6>
              <Badge
                pill
                className='p-2 me-1 d-flex flex-column custom-badge'
                onClick={() => removeAllFilters('order_by')}
              >
                <div className='d-flex justify-content-between align-items-center'>
                  <span>
                    {formatFilterValue(formatFilterOption('order_by', filters['order_by'][0]))}
                  </span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className='ps-2 remove-icon fw-bold text-opacity-100'
                  />
                </div>
              </Badge>
            </div>
          )}

          {Object.keys(filters).some((key) => key !== 'order_by' && filters[key].length > 0) && (
            <div className='d-flex align-items-center mb-2'>
              <h6 className='text-muted my-auto'>Filters selected:</h6>
            </div>
          )}
          {Object.keys(filters).map(
            (key) =>
              key !== 'order_by' &&
              filters[key].length > 0 && (
                <React.Fragment key={index++}>
                  {filters[key].map((filterValue, innerIndex) => (
                    <Badge
                      key={innerIndex}
                      pill
                      className='p-2 me-1 d-flex flex-column custom-badge mb-2'
                      onClick={() => removeFilter(key, filterValue)}
                    >
                      <div
                        className='d-flex justify-content-between align-items-center'
                        onClick={() => removeFilter(key, filterValue)}
                      >
                        <span>{formatFilterName(getKeyByValue(filterNames, key))}</span>
                        <div style={{ whiteSpace: 'pre' }}> </div>
                        <React.Fragment>
                          {formatFilterValue(formatFilterOption(key, filterValue))}
                        </React.Fragment>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className='ps-2 remove-icon fw-bold text-opacity-100'
                        />
                      </div>
                    </Badge>
                  ))}
                </React.Fragment>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default SelectedFiltersDisplay;
