import React, { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import './filter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

const Sorting = ({ filters, setFilters }) => {
  const [dropdown, setDropdown] = useState({
    active: false,
  });

  const catMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (catMenu.current && dropdown.active && !catMenu.current.contains(e.target)) {
      setDropdown({ ...dropdown, active: false });
    }
  };
  document.addEventListener('mousedown', closeOpenMenus);

  const toggleAccordion = () => {
    setDropdown({ ...dropdown, active: !dropdown.active });
  };

  const orderBy = (name) => {
    let order = ['entrepreneurial_intention', 'idea_type', '-motivation_score'];

    switch (name) {
      case 'entrepreneurial_intention':
        order = ['-entrepreneurial_intention', 'idea_type', '-motivation_score'];
        break;
      case 'idea_type':
        order = ['idea_type'];
        break;
      case 'motivation_score':
        order = ['-motivation_score'];
        break;
      case 'team_status':
        order = ['team_status'];
        break;
    }

    setFilters({ ...filters, order_by: order });
    // console.log('In ordering ' + JSON.stringify(filters.order_by));
  };

  const clearSelection = () => {
    const radioBtns = document.querySelectorAll('input[type=radio]');
    radioBtns.forEach((radio) => {
      if (radio.checked === true) {
        radio.checked = false;
      }
    });

    // const defaultOrder = ['-entrepreneurial_intention', 'idea_type', '-motivation_score'];
    setFilters({ ...filters, order_by: [] });
  };

  return (
    <div className='dropdowns ms-3' ref={catMenu}>
      <Button
        className={`${dropdown.active ? 'btn-info' : 'btn-light'}`}
        onClick={() => toggleAccordion()}
      >
        <FontAwesomeIcon icon={faSort} className='me-2 text-primary' />
        Sort
      </Button>
      <div className={`${dropdown.active ? 'dropdowns-menu active' : 'dropdowns-menu'}`}>
        <div id='accordion'>
          <div className=''>
            <div className='form-check mb-1 radios'>
              <input
                className='form-check-input border border-dark'
                type='radio'
                name='flexRadioDefault'
                id='flexRadioDefault1'
                onChange={() => orderBy('entrepreneurial_intention')}
              />
              <label className='form-check-label' htmlFor='flexRadioDefault1'>
                Entrepreneurial intention
              </label>
            </div>
            <div className='form-check my-2 radios'>
              <input
                className='form-check-input border border-dark'
                type='radio'
                name='flexRadioDefault'
                id='flexRadioDefault2'
                onChange={() => orderBy('motivation_score')}
              />
              <label className='form-check-label' htmlFor='flexRadioDefault2'>
                Entrepreneurial motivation score
              </label>
            </div>
            <div className='form-check my-2 radios'>
              <input
                className='form-check-input border border-dark'
                type='radio'
                name='flexRadioDefault'
                id='flexRadioDefault3'
                onChange={() => orderBy('idea_type')}
              />
              <label className='form-check-label' htmlFor='flexRadioDefault3'>
                Idea type
              </label>
            </div>
            <div className='form-check mt-2 radios'>
              <input
                className='form-check-input border border-dark'
                type='radio'
                name='flexRadioDefault'
                id='flexRadioDefault4'
                onClick={() => orderBy('team_status')}
              />
              <label className='form-check-label' htmlFor='flexRadioDefault4'>
                Team status
              </label>
            </div>
          </div>
        </div>
        <div>
          <Button variant='link' className='text-decoration-none mt-2 p-0' onClick={clearSelection}>
            Clear selection
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sorting;
