import React from 'react';
import { Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCode,
  faRightToBracket,
  faRightFromBracket,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthorizationModal from '../modals/AuthorizationModal';
import { useIsAuthenticated } from 'react-auth-kit';
import { useGlobalContext } from '../../context';
import { useSignOut } from 'react-auth-kit';

const Navibar = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { authModalShow, setAuthModalShow } = useGlobalContext();

  const isAuthenticated = useIsAuthenticated();

  const [isLogin, setIsLogin] = useState(true);
  const [step, setStep] = useState(0);

  const showModal = (authType) => {
    if (authType === 'login') {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }

    setAuthModalShow(true);
  };
  const closeModal = () => {
    setAuthModalShow(false);
    setStep(0);
  };

  const logout = () => {
    signOut();
    navigate('/');
  };

  useEffect(() => {
    console.log('Auth?  ' + isAuthenticated());
    if (!isAuthenticated()) {
      // setShow(true);
    }
  }, []);

  return (
    <>
      <Navbar expand='lg' bg='main' variant='dark'>
        <Container fluid>
          <Navbar.Brand as={NavLink} to={isAuthenticated() ? '/dashboard' : '/'} className=''>
            <FontAwesomeIcon icon={faCode} className='mx-1 ' />
            ULTIMATE HACKATHON
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navBarScroll'>
            <Nav className='ms-auto'>
              {isAuthenticated() ? (
                <Button variant='outline-info' className='btn-lg m-1 text-white' onClick={logout}>
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  Log out
                </Button>
              ) : (
                <>
                  <Button
                    variant='outline-info'
                    className='btn-lg m-1 text-white'
                    onClick={() => showModal('login')}
                  >
                    <FontAwesomeIcon icon={faRightToBracket} className='pe-2' />
                    Log in
                  </Button>
                  <Button
                    variant='outline-info text-white'
                    className='btn-lg m-1'
                    onClick={() => showModal('register')}
                  >
                    <FontAwesomeIcon icon={faUserPlus} className='pe-2' />
                    Register
                  </Button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <AuthorizationModal
        closeModal={closeModal}
        show={authModalShow}
        isLogin={isLogin}
        setIsLogin={setIsLogin}
        step={step}
        setStep={setStep}
      />
    </>
  );
};

export default Navibar;
