import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SpinnerLoad from '../../loading/SpinnerLoad';
import useAuthorizedRequest from '../../../api/axiosConfiguration';

const GenerateTeams = ({ show, setShow, loading, setLoading, getTeams, hackathonId }) => {
  const closeModal = () => {
    setShow(false);
  };

  const makeAuthorizedRequest = useAuthorizedRequest();

  const generateTeams = async () => {
    setLoading(true);
    try {
      const response = await makeAuthorizedRequest(
        `/hackathons/${hackathonId}/generate-teams`,
        {},
        'post',
      );

      console.log('Response from generate teams : ' + JSON.stringify(response));
    } catch (err) {
      console.log(err);
    } finally {
      getTeams();
    }
    setLoading(false);
    closeModal();
  };

  return (
    <Modal show={show} className='w-100 py-5 ' onHide={closeModal} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Generate teams</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <p className='fw-bold'>Team generation notice</p>
        <p>
          Please note the following constraints and considerations in our automatic team generation
          process:
        </p>
        <ul className='list-group list-group-numbered'>
          <li className='list-group-item border-0'>
            <b>Basic team structure:</b> Each team will ideally have one developer, one designer,
            and one idea owner.
          </li>
          <li className='list-group-item border-0'>
            <b>Additional roles:</b> Efforts will be made to include business and marketing
            professionals in teams, subject to availability.
          </li>
          <li className='list-group-item border-0'>
            <b>Entrepreneurial motivation:</b> We will try to group individuals with similar level
            of entrepreneurial motivation together.
          </li>
          <li className='list-group-item border-0'>
            <b>Existing teams:</b> We will try to assign additional participants to teams that are
            already formed, but incomplete.
          </li>
          <li className='list-group-item border-0'>
            <b>Team size variability:</b> Some teams might end up larger or smaller than the ideal
            composition. These instances will be highlighted with a special warning.
          </li>
          <li className='list-group-item border-0'>
            <b>Unassigned participants:</b> There is a possibility that not all participants will be
            assigned to a team, especially in cases of an excess of certain roles (e.g., we will not
            put more than one developer per team).
          </li>
          <li className='list-group-item border-0'>
            <b>Participant responses:</b> The team generation process will <b>not</b> consider
            responses regarding team status or roles needed. Please make sure that pre-formed teams
            are created and that the participants who are already part of pre-fromed team have been
            assigned to a team.
          </li>
        </ul>
      </Modal.Body>

      <Row className='my-2 mb-4 px-4'>
        <Col className='d-flex gap-2'>
          {loading ? (
            <SpinnerLoad />
          ) : (
            <>
              <Button variant='outline-dark' className='w-50' onClick={closeModal}>
                Cancel
              </Button>
              <Button className='w-50 btn-prim' onClick={generateTeams}>
                Generate teams
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default GenerateTeams;
