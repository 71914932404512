import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SpinnerLoad from '../../loading/SpinnerLoad';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import useAuthorizedRequest from '../../../api/axiosConfiguration';

const EditHackathon = ({ show, setShow, loading, hackathon, setLoading, getHackathons }) => {
  const [hackathonToEdit, setHackathonToEdit] = useState({
    name: undefined,
    date: '',
  });
  const [errs, setErrs] = useState('');

  const handleChange = (type, e) => {
    setHackathonToEdit({
      ...hackathonToEdit,
      [type]: e.target.value,
    });
  };

  const closeModal = () => {
    setShow(false);
    setHackathonToEdit({ ...hackathonToEdit, name: undefined, date: '' });
    setErrs('');
  };

  const makeAuthorizedRequest = useAuthorizedRequest();

  // const token = GetCookie('_auth');
  const editHackathon = async () => {
    const now = new Date();
    const formattedNow = now.toISOString().slice(0, 16);
    if (
      (hackathonToEdit.name && hackathonToEdit.name.length > 255) ||
      hackathonToEdit.name === ''
    ) {
      setErrs('Title should be between 1 and 255 characters long');
      return;
    } else if (hackathonToEdit.date !== '' && hackathonToEdit.date < formattedNow) {
      setErrs('Start date should be in future');
      return;
    }
    setLoading(true);
    try {
      const response = await makeAuthorizedRequest(
        `/hackathons/${hackathon.id}`,
        {
          name: hackathonToEdit.name === undefined ? hackathon.name : hackathonToEdit.name,
          date: hackathonToEdit.date === '' ? hackathon.date : hackathonToEdit.date,
        },
        'patch',
      );

      console.log('Response from edit : ' + JSON.stringify(response));
    } catch (err) {
      console.log('ErrrorMEEES ' + err);
    } finally {
      getHackathons();
    }
    setLoading(false);
    setShow(false);
    setHackathonToEdit({ ...hackathonToEdit, name: undefined, date: '' });
    setErrs('');
  };

  return (
    <Modal show={show} className='py-5 w-100' onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Edit hackathon</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <h5 className='fw-bold mb-3'> Hackathon`s details</h5>
        <Form>
          <Form.Group controlId='formBasicTitle'>
            <FloatingLabel controlId='floatingTitle' label='Title'>
              <Form.Control
                type='text'
                name='name'
                className='form-control-lg text-wrap'
                onChange={(e) => handleChange('name', e)}
                value={hackathonToEdit.name !== undefined ? hackathonToEdit.name : hackathon.name}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId='formBasicDateTime' className='mt-2'>
            <FloatingLabel controlId='floatingDateTime' label='Start date'>
              <Form.Control
                type='datetime-local'
                name='dateTime'
                className='form-control-lg'
                value={
                  hackathonToEdit.date !== ''
                    ? hackathonToEdit.date
                    : hackathon.date
                    ? hackathon.date.substring(0, hackathon.date.length - 4)
                    : ''
                }
                onChange={(e) => handleChange('date', e)}
              />
            </FloatingLabel>
          </Form.Group>
          {errs && <p className='text-danger text-center mt-3 mb-0'>{errs}</p>}
        </Form>
      </Modal.Body>

      <Row className='my-2 mb-4 px-4'>
        <Col className='d-flex gap-2'>
          {loading ? (
            <SpinnerLoad />
          ) : (
            <>
              <Button variant='outline-dark' className='w-50' onClick={closeModal}>
                Cancel
              </Button>
              <Button className='w-50 btn-prim' onClick={editHackathon}>
                Edit
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default EditHackathon;
