import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const DeleteHackathon = () => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Delete hackathon</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <p className='text-muted'>
          Are you sure you want to delete this hackathon? This action is permanent and will remove
          all associated data, including participants, teams, and event details. Deleted hackathons
          cannot be recovered.
        </p>
        <Row className='my-3'>
          <Col col={12} className='d-flex gap-2'>
            <Button variant='outline-dark' className='w-50'>
              Cancel
            </Button>
            <Button className='w-50 btn-danger'>Delete hackathon</Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export default DeleteHackathon;
