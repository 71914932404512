import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useIsAuthenticated } from 'react-auth-kit';

const RequireAuth = () => {
  const authenticated = useIsAuthenticated();
  const location = useLocation();

  return authenticated() ? <Outlet /> : <Navigate to='/' state={{ from: location }} replace />;
};

export default RequireAuth;
