import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CreateHackathon from './CreateHackathon';

const CreateHackathonModal = ({ setShow, show, addHackathon }) => {
  return (
    <Modal show={show} className='mt-5 pt-5 w-100' onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-2 px-2 text-main-dark'>Create hackathon</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <CreateHackathon addHackathon={addHackathon} />
      </Modal.Body>
    </Modal>
  );
};

export default CreateHackathonModal;
