import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';

const Notification = ({ type, msg, remove }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      remove();
    }, 2500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Alert
      variant='light'
      className={`mx-auto border border-${type}`}
      style={{
        width: '210px',
        height: '70px',
        position: 'fixed',
        top: '40px',
        left: '0',
        right: '0',
        zIndex: '1',
      }}
    >
      <div className='d-flex align-items-center text-break'>
        <p className={`text-${type} mx-auto my-auto fs-6`}>{msg}</p>
      </div>
    </Alert>
  );
};

export default Notification;
