import React from 'react';

const StepTwo = () => {
  return (
    <>
      <h4>Deliver the awards</h4>
      <p className='mt-3'>
        Often teams would like to earn cash at the end of a hackathon. But to match the teams with
        the awards that would suit their specific needs, it is necessary to select the right awards,
        use an appropriate final pitch template, and a judging criteria.
      </p>
      <p className='fw-bold mt-4 mb-1'>Final pitch template (3 minutes)</p>
      <p>
        <b>Problem Statement and target audience (50 seconds)</b>: Describe the problem you want to
        solve and emphasize the need for a solution. Clearly define your project`s user base.
        Describe their needs and pain points that your project addresses.
        <br />
        <br />
        <b>Demo (50 seconds) </b>: Showcase your project`s key features and functionalities that
        solve the identified problem.
        <br />
        <br />
        <b>Business Model (30 seconds)</b>: Present a concise overview of your project`s business
        model, including the monetization strategy. Highlight potential funding opportunities or
        revenue streams you`ve identified.
        <br />
        <br />
        <b>Future Plans and Needs (20 seconds)</b>: Point out the main needs of your project, such
        as funding or marketing. Highlight any specific awards that would help you further develop
        the project.
        <br />
        <br />
        <b>Entrepreneurial motivation (10 seconds)</b>: Mention if you want to create a startup from
        your project or if you would like to continue working on your project in any way.
        <br />
        <br />
        <b>Team (20 seconds)</b>: Introduce your team members, including their names, roles, and a
        brief background or expertise related to the project.
      </p>
      <p className='fw-bold mt-4 mb-1'>Judging criteria </p>
      <p>
        <b>Entrepreneurial motivation</b>: Evaluate the team`s motivation and determination to
        create a startup. Teams with strong motivation to create a startup should be prioritized for
        awards.
        <br />
        <br />
        <b>Problem-to-solution Match</b>: Assess how effectively the project solves the identified
        problem. Evaluate the alignment between the problem statement and the proposed solution.
        <br />
        <br />
        <b>Realistic idea</b>: Consider the feasibility of the idea`s implementation. Think about
        how complex it would be for the team to technically develop the idea.
        <br />
        <br />
        <b>Market Validation</b>: Assess if the team has identified a space in the market for their
        project, their target audience correctly and if they have already validated their idea with
        them.
        <br />
        <br />
        <b>Business Model</b>: Evaluate the viability and rationality of the team`s monetization
        strategy. Consider the sustainability and potential profitability of their business model.
        <br />
        <br />
        <b>Current Needs to Continue Working on the Project</b>: Evaluate the team`s expressed needs
        to continue working on their projects and assign the awards accordingly.
        <br />
        <br />
        <b>Team composition</b>: Assess if the team is diverse and capable enough to deliver what
        they promise.
        <br />
        <br />
        <b>Presentation skills</b>: Evaluate how clear, engaging and creative the presentation was.
      </p>
    </>
  );
};

export default StepTwo;
