import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
// import GetCookie from '../../../hooks/getCookie';
// import api from '../../../api/axiosConfig';
import useAuthorizedRequest from '../../../api/axiosConfiguration';

const DeleteParticipant = ({
  teams,
  closeModal,
  selectedParticipants,
  setSelectedParticipants,
  hackathonId,
  getTeams,
}) => {
  const participantsToDelete = selectedParticipants;

  const createParticipantsWithTeamId = (teams, participants) => {
    const result = [];

    participants.forEach((participant) => {
      const team = teams.find((t) => t.participants.some((p) => p.id === participant.id));

      const participantWithTeamId = {
        teamId: team ? team.id : null,
        ...participant,
      };

      result.push(participantWithTeamId);
    });

    return result;
  };

  const participantsWithTeamId = createParticipantsWithTeamId(teams, participantsToDelete);

  // const token = GetCookie('_auth');
  const makeAuthorizedRequest = useAuthorizedRequest();

  const deleteParticipant = async () => {
    const selectedParticipantsIDs = selectedParticipants.map(({ id }) => ({ id }));

    try {
      await Promise.all(
        selectedParticipantsIDs.map(async (participant) => {
          console.log('participantIndex: ' + participant.id);

          if (participant) {
            try {
              await makeAuthorizedRequest(
                `/hackathons/${hackathonId}/participants/${participant.id}`,
                {},
                'delete',
              );
              // await api.delete(`/hackathons/${hackathonId}/participants/${participant.id}`, {
              //   headers: {
              //     Authorization: `Bearer ${token}`,
              //   },
              // });
              setSelectedParticipants([]);
            } catch (err) {
              console.log(err);
            }
          }
        }),
      );
    } catch (err) {
      console.log(err);
    } finally {
      getTeams();
    }

    closeModal();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Delete Participant</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <p className='text-muted'>
          Are you sure you want to delete this participant? This action is irreversible and will
          permanently remove them from the event. Deleted participants cannot be recovered.
        </p>
        <Row>
          <Col>
            <h4 className='mb-2'>Participants details:</h4>
            <Table bordered className=''>
              <thead>
                <tr className='table-active'>
                  <th>Name</th>
                  <th>Team</th>
                </tr>
                {participantsWithTeamId?.map((participant) => {
                  return (
                    <tr key={participant.id}>
                      <td>
                        {participant.first_name} {participant.last_name}
                      </td>
                      {participant.teamId ? (
                        <td>Team #{participant.teamId}</td>
                      ) : (
                        <td>Not assigned</td>
                      )}
                    </tr>
                  );
                })}
              </thead>
            </Table>
          </Col>
        </Row>
        <Row className='my-3'>
          <Col className='d-flex'>
            <Button variant='outline-dark' className='w-50 me-3' onClick={closeModal}>
              Cancel{' '}
            </Button>
            <Button className='btn-danger w-50' onClick={deleteParticipant}>
              Delete Participant
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export default DeleteParticipant;
