import Navibar from './components/header/Navibar';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/footer/Footer';
import './index.css';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';
import SingleHackathon from './pages/SingleHackathon';
import CreateHackathonModal from './components/createHackathon/CreateHackathon';
import RequireAuth from './components/auth/RequireAuth';
import GuidelineElement from './components/guidelines/GuidelineElement';
import ResetPassword from './pages/ResetPassword';

function App() {
  return (
    <div className='App'>
      <Navibar />
      <Routes>
        <Route exact path='/' element={<LandingPage />}></Route>
        <Route path='/reset-password' element={<ResetPassword />}></Route>
        <Route element={<RequireAuth />}>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/hackathons/:id' element={<SingleHackathon />}></Route>
          <Route path='/create' element={<CreateHackathonModal />}></Route>
        </Route>
      </Routes>
      <GuidelineElement />
      <Footer />
    </div>
  );
}
export default App;
