import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useIsAuthenticated } from 'react-auth-kit';
import { useGlobalContext } from '../context';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  // const {alert, showAlert} = useGlobalContext();

  const { setAuthModalShow } = useGlobalContext();

  const checkAuth = () => {
    if (!isAuthenticated()) {
      // showAlert(true, `danger`, `Please log in`)
      setAuthModalShow(true);
      return;
    }
    navigate('/dashboard');
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      setAuthModalShow(true);
    }
  }, []);

  return (
    <Container className='pt-5 mt-5 d-flex'>
      <Row className='d-flex my-auto '>
        <h1 className='text-white display-1 fw-bold text-center py-2'>
          Welcome to<br></br>Ultimate Hackathon
        </h1>
        <p className='text-white text-center fs-4'>
          Foster startup creation with our research-backed registration forms and guidelines. Gain
          insights into entrepreneurial goals of your participants, streamline team formation and
          learn about best practices of hackathon management.
        </p>
        <div to='/dashboard' className='d-flex mx-auto w-50'>
          <Button className='btn-lg fs-4 btn-prim w-25 mx-auto my-3 py-2 px-1' onClick={checkAuth}>
            Start now
          </Button>
        </div>
      </Row>
    </Container>
  );
};

export default LandingPage;
