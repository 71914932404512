import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import GetCookie from '../../../hooks/getCookie';
// import api from '../../../api/axiosConfig';
import useAuthorizedRequest from '../../../api/axiosConfiguration';

const DropFromTeam = ({
  teams,
  closeModal,
  selectedParticipants,
  setSelectedParticipants,
  setTeams,
  hackathonId,
  getTeams,
}) => {
  // const token = GetCookie('_auth');
  const makeAuthorizedRequest = useAuthorizedRequest();

  const dropFromTeam = async () => {
    const modifiedTeams = [...teams];
    const selectedParticipantsIDs = selectedParticipants.map(({ id }) => ({ id }));

    const patchRequests = [];
    selectedParticipantsIDs.forEach(async (participant) => {
      for (const team of modifiedTeams) {
        const participantIndex = team.participants.findIndex((p) => p.id === participant.id);
        if (participantIndex !== -1) {
          team.participants.splice(participantIndex, 1);

          const patchPromise = await makeAuthorizedRequest(
            `/hackathons/${hackathonId}/teams/${team.id}`,
            {
              participants: team.participants,
              name: team.name,
              warnings: team.warnings,
            },
            'patch',
          );
          // const patchPromise = api.patch(
          //   `/hackathons/${hackathonId}/teams/${team.id}`,
          //   {
          //     participants: team.participants,
          //     name: team.name,
          //     idea: team.idea,
          //     warnings: team.warnings,
          //   },
          //   {
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     },
          //   },
          // );
          patchRequests.push(patchPromise);
        }
      }
      try {
        const responses = await Promise.all(patchRequests);

        console.log('Responses: ', responses);

        let modifiedTeams = responses.map((response) => response);
        setTeams(modifiedTeams);
        setSelectedParticipants([]);
      } catch (err) {
        console.log(err);
      } finally {
        getTeams();
      }
    });

    closeModal();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Drop from team</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <Row className='my-3 pb-2'>
          <Col>Are you sure you want to drop the participants from the team?</Col>
        </Row>
        <Row className='my-2'>
          <Col className='d-flex'>
            <Button variant='outline-dark' className='w-50 me-3' onClick={closeModal}>
              Cancel{' '}
            </Button>
            <Button className='w-50 btn-prim' onClick={() => dropFromTeam()}>
              Drop from team
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export default DropFromTeam;
