import React, { Component } from 'react';
import { Button, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SpinnerLoad from '../loading/SpinnerLoad';
import backendUrl from '../../config';

export class Confirm extends Component {
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  next = (e) => {
    e.preventDefault();
    this.props.addHackathon();
    const {
      values: { response },
    } = this.props;
    if (response) {
      this.props.nextStep();
    }
  };

  stringToDate = (string) => {
    const date = new Date(string);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const readableDate = `${day}-${month}-${year}, ${hours}:${minutes}`;

    return readableDate;
  };

  render() {
    const {
      values: { loading },
    } = this.props;

    return (
      <Container>
        {!loading ? (
          <>
            <p className='text-main-secondary'>Step 2 of 2</p>
            <Row>
              <Col className='pt-0 mt-0'>
                <h5 className='fw-bold'>Preview registration form</h5>
              </Col>
            </Row>
            <Row>
              <Col className='mb-3'>
                <p className=' me-2'>
                  The registration form will feature necessary questions that users can access. You
                  can view an example of the registration form through the following link.
                </p>
                <div>
                  <a
                    className='text-primary text-decoration-underline fw-bold'
                    style={{ cursor: 'pointer' }}
                    href={`${backendUrl}/surveys/example`}
                    target='”_blank”'
                  >
                    Explore an example hackathon
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex gap-2'>
                <Button variant='outline-dark' className='w-50' onClick={this.back}>
                  Back
                </Button>
                <Button className='w-50 btn-prim' onClick={this.next}>
                  Finish
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <div className='d-flex'>
            <Col className='pt-0 mt-0'>
              <Row>
                <h3 className='fw-bold text-main-dark'>Your hackathon is being created</h3>
              </Row>
              <Row>
                <SpinnerLoad />
              </Row>
            </Col>
          </div>
        )}
      </Container>
    );
  }
}

export default Confirm;
