const formatFilterOption = (filterKey, filterValue) => {
  //console.log("filterValue: ",filterValue)
  const value = filterValue.replace(/_/g, ' ');
  switch (filterKey) {
    case 'entrepreneurial_intention':
      return '';
    case 'expectations__isnull':
      return '';
    case 'idea_help__isnull': //Special help filter
      return '';
    case 'first_hackathon__in':
      return value == 0 ? 'First timer' : 'Not first timer';
    case 'skills__in':
      return value === 'Other' ? 'Other skills' : value;
    case 'idea_type__in':
      return value === 'NO IDEA' ? 'No idea' : value;
    case 'team_status__in':
      return value === 'Yes_not_full' ? 'Yes, not full' : value;
    case 'team_roles_missing__in':
      return value === 'Other' ? 'Other roles missing' : value;
    case 'age__in':
      return mapAgeValue(value);
    case 'gender__in':
      return value === 'PNTS'
        ? 'Prefer not to say'
        : value === 'M'
        ? 'Male'
        : value === 'F'
        ? 'Female'
        : 'Non-binary';
    case 'nationality__in':
      return filterValue;
    case 'meal_preference__in':
      return value === 'ME' ? 'Meat eater' : value === 'VG' ? 'Vegeterian' : 'Vegan';
    case 'education__in':
      return value === 'HS'
        ? 'High school'
        : value === 'BA'
        ? 'Bachelors'
        : value === 'MS'
        ? 'Masters'
        : value === 'DOC'
        ? 'Doctorate'
        : 'Prefer not to say';
    case 'employment__in':
      return value === 'CEMP'
        ? 'Company employee'
        : value === 'SEMP'
        ? 'Self-employed'
        : value === 'UEMP'
        ? 'Unemployed'
        : 'Prefer not to say';
    case 'order_by':
      return formatSortValues(value);
    default:
      return value;
  }
};

const mapAgeValue = (originalValue) => {
  switch (originalValue) {
    case 'AGE 18 24':
      return '18-24';
    case 'AGE 25 34':
      return '25-34';
    case 'AGE 35 44':
      return '35-44';
    case 'AGE 45 54':
      return '45-54';
    case 'AGE 55 64':
      return '55-64';
    case 'AGE 65 74':
      return '65-74';
    case 'AGE 75 UP':
      return '75+';
    default:
      return originalValue;
  }
};

const formatSortValues = (inputString) => {
  if (inputString.charAt(0) === '-') {
    return inputString.charAt(1).toUpperCase() + inputString.slice(2).replace(/_/g, ' ');
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
};

export default formatFilterOption;
