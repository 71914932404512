import React from 'react';
import SearchFilters from './SearchFilters';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';

const FilterModal = ({ show, setShow, filters, setFilters, nationalities }) => {
  const clearAllFilters = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      entrepreneurial_intention: [],
      motivation_score__gte: [],
      motivation_score__lte: [],
      first_hackathon__in: [],
      expectations__isnull: [],
      skills__in: [],
      idea_type__in: [],
      idea_help__isnull: [],
      team_status__in: [],
      team_roles_missing__in: [],
      age__in: [],
      gender__in: [],
      nationality__in: [],
      meal_preference__in: [],
      education__in: [],
      employment__in: [],
      search: [],
      order_by: [],
    }));
  };

  return (
    <Offcanvas show={show} onHide={() => setShow(false)} placement='end'>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className='mt-4 ms-4 mb-3 fw-bolder'>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <SearchFilters filters={filters} setFilters={setFilters} nationalities={nationalities} />
        <Col className='m-3'>
          <Button variant='link' className='text-decoration-none fw-bold' onClick={clearAllFilters}>
            Clear all
          </Button>
        </Col>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FilterModal;
