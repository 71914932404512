import Spinner from 'react-bootstrap/Spinner';

function SpinnerLoad() {
  return (
    <Spinner animation='border' role='status' className='mx-auto' style={{ color: '#5d42d1' }}>
      <span className='visually-hidden'>Loading...</span>
    </Spinner>
  );
}

export default SpinnerLoad;
