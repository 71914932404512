import React from 'react';
import Modal from 'react-bootstrap/Modal';
import SignUpForm from '../forms/SignUpForm';
import LoginForm from '../forms/LoginForm';
import ResetPassword from '../forms/ResetPassword';
import CheckEmail from '../forms/CheckEmail';

const AuthorizationModal = ({ show, closeModal, isLogin, setIsLogin, step, setStep }) => {
  const nextStep = () => {
    setStep((prev) => (prev += 1));
  };

  const prevStep = () => {
    setStep((current) => (current -= 1));
  };

  const renderLoginContent = (step) => {
    switch (step) {
      case 0:
        return <LoginForm setIsLogin={setIsLogin} closeModal={closeModal} nextStep={nextStep} />;

      case 1:
        return <ResetPassword nextStep={nextStep} prevStep={prevStep} />;

      case 2:
        return <CheckEmail />;
    }
  };

  const renderAuthTitle = () => {
    switch (step) {
      case 0:
        return 'Log in';
      case 1:
        return 'Enter email';
      case 2:
        return 'Check email';
    }
  };

  return (
    <Modal show={show} className='mt-5 pt-5 w-100 ' onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>
          {isLogin ? renderAuthTitle() : 'Sign up'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        {isLogin ? (
          renderLoginContent(step)
        ) : (
          <SignUpForm setIsLogin={setIsLogin} closeModal={closeModal} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AuthorizationModal;
