import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faLink, faTrashCan, faPencil } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import CreateHackathonModal from '../components/createHackathon/CreateHackathonModal';
import DeleteHackathon from '../components/modals/hackathonOperation/DeleteHackathon';
import EditHackathon from '../components/modals/hackathonOperation/EditHackathon';
import Notification from '../components/alert/Notification';
import { useGlobalContext } from '../context';
import useAuthorizedRequest from '../api/axiosConfiguration';

const Dashboard = () => {
  const [hackathons, setHackathons] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const { alert, showAlert, handleShowDropdown } = useGlobalContext();
  const [deleteHackathonId, setDeleteHackathonId] = useState(null);
  const [editHackathonId, setEditHackathonId] = useState({});
  const [loading, setLoading] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const handleShowDelete = (hackathon) => {
    setDeleteHackathonId(hackathon.id);
    setDeleteModalShow(true);
  };

  const handleShowEdit = (hackathon) => {
    setEditShow(true);
    setEditHackathonId(hackathon);
  };

  const navigate = useNavigate();

  const openHackathon = (id) => {
    navigate(`/hackathons/${id}`);
  };

  const openModal = () => {
    setShow(true);
    handleShowDropdown(1);
  };

  const makeAuthorizedRequest = useAuthorizedRequest();

  const addHackathon = async (hackathon) => {
    try {
      const response = await makeAuthorizedRequest(
        `/hackathons/`,
        {
          name: hackathon.title,
          date: hackathon.date,
        },
        'post',
      );

      setHackathons([...hackathons, hackathon]);
      return response;
    } catch (err) {
      console.log(err);
    } finally {
      getHackathons();
    }
  };

  const getHackathons = async () => {
    try {
      const response = await makeAuthorizedRequest(`/hackathons/`, {}, 'get');
      setHackathons(response);
      // const response = await api.get('/hackathons/', {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      console.log('Response from getHackathons : ' + JSON.stringify(response.data));
      // setHackathons(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteHackathon = async () => {
    setLoading(true);
    try {
      // const response = await api.delete(`/hackathons/${deleteHackathonId}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await makeAuthorizedRequest(
        `/hackathons/${deleteHackathonId}`,
        {},
        'delete',
      );
      console.log('Response from edit : ' + JSON.stringify(response));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setDeleteModalShow(false);
      getHackathons();
    }
  };

  const handleCopyClick = (link) => {
    showAlert(true);
    navigator.clipboard.writeText(link);
  };

  function formatDatetime(datetimeString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    };
    return new Date(datetimeString).toLocaleString('en-US', options);
  }

  useEffect(() => {
    getHackathons();
  }, []);

  return (
    <Container fluid className='py-5 dashboard-wrapper'>
      {alert.show && <Notification remove={showAlert} type={'primary'} msg={'Link copied'} />}
      <Row className='d-flex align-items-center'>
        <Col md={4} className='mx-auto d-flex justify-content-center'>
          <h1 className='text-main-dark display-5 fw-bold'>My hackathons</h1>
        </Col>
        <Col md={{ span: 4, offset: 4 }} className='d-flex justify-content-center'>
          <Button className='fs-4 btn-prim' onClick={openModal}>
            <FontAwesomeIcon icon={faPlus} className='me-2 fs-5' />
            Create hackathon
          </Button>
        </Col>
      </Row>
      {show && <CreateHackathonModal show={show} setShow={setShow} addHackathon={addHackathon} />}

      <Row className='p-5'>
        {console.log(hackathons)}
        {hackathons.length ? (
          hackathons?.map((hackathon, index) => {
            return (
              <Card
                style={{
                  width: '25rem',
                  minHeight: '25rem',
                  background: '#EEE8FA',
                  borderRadius: '25px',
                }}
                key={index}
                className='p-3 m-3 shadow-sm'
              >
                <Row className='align-items-center p-3'>
                  <Col className='text-end'>
                    <FontAwesomeIcon
                      icon={faLink}
                      className='text-primary pe-3 fs-4'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleCopyClick(hackathon.survey_link)}
                    />
                    <FontAwesomeIcon
                      icon={faPencil}
                      className='text-primary pe-3 fs-4'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleShowEdit(hackathon)}
                    />
                    <EditHackathon
                      show={editShow}
                      setShow={setEditShow}
                      loading={loading}
                      hackathon={editHackathonId}
                      setLoading={setLoading}
                      getHackathons={getHackathons}
                    />

                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className='text-danger fs-4'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleShowDelete(hackathon)}
                    />
                    <DeleteHackathon
                      show={deleteModalShow}
                      setDeleteModalShow={setDeleteModalShow}
                      deleteHackathon={deleteHackathon}
                      id={deleteHackathonId}
                      loading={loading}
                    />
                  </Col>
                </Row>
                <Card.Body
                  className='d-flex flex-column'
                  style={{ cursor: 'pointer' }}
                  onClick={() => openHackathon(hackathon.id)}
                >
                  <Card.Title className='fs-4 mt-auto'>{hackathon.name}</Card.Title>
                  <Card.Text className=''>
                    <p className='text-muted mb-0'>{formatDatetime(hackathon.date)}</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            );
          })
        ) : (
          <h3 className='text-muted m-1'>Nothing here yet...</h3>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
