import axios from 'axios';
import backendUrl from '../config';

export default axios.create({
  baseURL: backendUrl,
  headers: {
    'ngrok-skip-browser-warning': 'true',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
