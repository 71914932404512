import React, { useState, useRef } from 'react';
import './guidelines.css';
import GuidelineModal from './GuidelineModal';
import { useIsAuthenticated } from 'react-auth-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useGlobalContext } from '../../context';

const GuidelineElement = () => {
  const [modalShow, setModalShow] = useState(false);
  // const [dropdown, setDropdown] = useState(false);
  const [step, setStep] = useState(1);
  const { dropdown, setDropdown, targetItem } = useGlobalContext();
  const auth = useIsAuthenticated();

  const openModal = (step) => {
    setStep(step);
    setModalShow(true);
  };

  const getTargetItemStyles = (nr) => {
    if (nr === targetItem) {
      return {
        background: 'linear-gradient(to right, #ccbdeb5c, #d2bdf3)',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(878, 722, 988, 0.68)',
        fontSize: '17px',
      };
    }
    return {};
  };

  const menu = useRef(null);
  const closeOpenMenus = (e) => {
    if (menu.current && dropdown && !menu.current.contains(e.target)) {
      setDropdown(false);
    }
  };
  document.addEventListener('mousedown', closeOpenMenus);

  return (
    <>
      {auth() && (
        <div className='dropdowns' ref={menu} style={{ zIndex: '5000' }}>
          <div className='guideline-element' onClick={() => setDropdown(!dropdown)}>
            <div className='guideline-icon'>
              <h4 className='my-auto'>
                <FontAwesomeIcon className='pe-2' icon={faCircleQuestion} />
                Guidelines
              </h4>
            </div>
            <div className={`${dropdown ? 'dropdowns-menuu active' : 'dropdowns-menuu'}`}>
              <div id='accordion'>
                <div className='card-header' id='headingOne'>
                  <div onClick={() => openModal(1)} style={getTargetItemStyles(1)}>
                    <p className='ps-1'>Set up the hackathon website</p>
                  </div>
                  <div onClick={() => openModal(2)} style={getTargetItemStyles(1)}>
                    <p className='ps-1'>Deliver the awards</p>
                  </div>
                  <div onClick={() => openModal(3)} style={getTargetItemStyles(1)}>
                    <p className='ps-1'>Post-hackathon support</p>
                  </div>
                  <div onClick={() => openModal(4)} style={getTargetItemStyles(4)}>
                    <p className='ps-1'>Selecting participants</p>
                  </div>
                  <div onClick={() => openModal(5)} style={getTargetItemStyles(5)}>
                    <p className='ps-1'>Manage suggestions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GuidelineModal show={modalShow} setShow={setModalShow} step={step} setStep={setStep} />
        </div>
      )}
    </>
  );
};

export default GuidelineElement;
