import React, { useState, useEffect } from 'react';
import api from '../api/axiosConfig';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AllParticipants from '../components/home/AllParticipants';
import { useNavigate, useParams } from 'react-router-dom';
import Sorting from '../components/filters/Sorting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectedFiltersDisplay from '../components/modals/SelectedFiltersDisplay';
import {
  faArrowLeft,
  faChartColumn,
  faTrashCan,
  faPlus,
  faUserPlus,
  faUserMinus,
  faLink,
  faSliders,
  faMagnifyingGlass,
  // faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { defaultTableFields } from '../data';
import { tableHeaders } from '../data';
// import { filterNames } from '../data';
import ParticipantsColumnModal from '../components/modals/ParticipantsColumnModal';
import ParticipantsTeams from '../components/participantsTable/ParticipantsTeams';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import BulkActModal from '../components/modals/bulkActModals/BulkActModal';
import Notification from '../components/alert/Notification';
import { useGlobalContext } from '../context';
import SpinnerLoad from '../components/loading/SpinnerLoad';
import FilterModal from '../components/filters/FilterModal';
import useDebounce from '../hooks/useDebounce';
import DeleteHackathon from '../components/modals/hackathonOperation/DeleteHackathon';
import GetCookie from '../hooks/getCookie';
import GenerateTeams from '../components/modals/hackathonOperation/GenerateTeams';
import useAuthorizedRequest from '../api/axiosConfiguration';

const SingleHackathon = () => {
  const [participants, setParticipants] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [filters, setFilters] = useState({
    entrepreneurial_intention: [],
    motivation_score__gte: [],
    motivation_score__lte: [],
    first_hackathon__in: [],
    expectations__isnull: [],
    skills__in: [],
    idea_type__in: [],
    idea_help__isnull: [],
    team_status__in: [],
    team_roles_missing__in: [],
    age__in: [],
    gender__in: [],
    nationality__in: [],
    meal_preference__in: [],
    education__in: [],
    employment__in: [],
    search: [],
    order_by: [],
  });

  const debouncedFilters = useDebounce(filters);

  const [fi, setFi] = useState(null);
  const [tableFields, setTableFields] = useState(defaultTableFields);

  const [showColumnsModal, setColumnsModalShow] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [bulkActModalShow, setBulkActModalShow] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [teams, setTeams] = useState([]);

  const [modalType, setModalType] = useState('');
  const { alert, showAlert, handleShowDropdown } = useGlobalContext();

  // let index = 0;

  const [singleHackathon, setSingleHackathon] = useState({
    name: '',
    date: '',
    id: null,
    survey_link: '',
  });

  const hackathonParam = useParams();

  const [loading, setLoading] = useState(false);
  const deleteHackathonId = hackathonParam.id;
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [generateTeamShow, setGenerateTeamShow] = useState(false);

  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedRequest();

  const back = () => {
    navigate(`/dashboard`);
  };

  const updateTableFields = (selectedFields) => {
    setTableFields(selectedFields);
  };

  const selectedParticipantFields = tableFields.map((header) => {
    return tableHeaders[header];
  });

  const openBulkModal = (action) => {
    if (action === 'deleteParticipant') {
      setModalType(action);
      setBulkActModalShow(true);
    } else if (selectedParticipants.some((participant) => !participant.consent_team_formation)) {
      showAlert(true, 'danger', 'No consent participant selected');
      return;
    }
    setModalType(action);
    setBulkActModalShow(true);
  };

  const handleCopyClick = () => {
    showAlert(true, 'primary', 'Link copied');
    navigator.clipboard.writeText(singleHackathon.survey_link);
  };

  const token = GetCookie('_auth');
  const deleteHackathon = async () => {
    setLoading(true);
    console.log('Deleting hackathon id ' + deleteHackathonId);
    try {
      const response = await makeAuthorizedRequest(
        `/hackathons/${deleteHackathonId}`,
        {},
        'delete',
      );

      setLoading(false);
      console.log('Response from delete : ' + JSON.stringify(response));
      setDeleteModalShow(false);
      navigate('/dashboard');
    } catch (err) {
      console.log(err);
    }
  };

  const setHackathon = (data) => {
    handleShowDropdown(4);

    setSingleHackathon({
      name: data.name,
      date: data.date,
      id: data.id,
      survey_link: data.survey_link,
    });
  };

  const getSingleHackathon = async () => {
    try {
      const response = await makeAuthorizedRequest(`/hackathons/${hackathonParam.id}`, {}, 'get');

      setHackathon(response);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    const filtersQuery = Object.entries(filters)
      .filter(([filterName, filterValues]) => filterName && filterValues.length > 0)
      .flatMap(([filterName, filterValues]) =>
        filterValues.map((value) => `${filterName}=${encodeURIComponent(value)}`),
      )
      .join('&');
    console.log('Filters in request : ' + JSON.stringify(filtersQuery));
    try {
      const [participantsResponse, teamsResponse] = await Promise.all([
        api.get(`/hackathons/${hackathonParam.id}/participants?${filtersQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        api.get(`/hackathons/${hackathonParam.id}/teams`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);
      const participantsData = participantsResponse.data.participants;
      setParticipants(participantsData);
      setNationalities(participantsResponse.data.nationalities);

      processTeams(participantsData, teamsResponse.data);
    } catch (err) {
      console.log(err);
    }
  };

  const processTeams = (participantsData, teamsInfo) => {
    let validTeams = [];

    for (let i = 0; i < teamsInfo.length; i++) {
      const aTeam = {};
      aTeam.id = teamsInfo[i].id;
      aTeam.name = teamsInfo[i].name;
      aTeam.warnings = teamsInfo[i].warnings;
      const teamParticipants = [];

      teamsInfo[i].participants.forEach((p) => {
        if (p) {
          p.incomplete = true;
          let result = participantsData.find((item) => item.id === p.id);
          teamParticipants.push(result ? result : p);
        }
      });
      // teamParticipants.sort((a, b) => a.id - b.id);
      aTeam.participants = teamParticipants;
      validTeams.push(aTeam);
    }
    validTeams = validTeams.sort((a, b) => a.id - b.id);
    setTeams(validTeams);
    setFi(participantsData);
  };

  const handleInputChange = (e) => {
    let input = e.target.value;
    if (input === '') {
      setFilters({ ...filters, search: [] });
      return;
    }
    setFilters({ ...filters, search: [input] });
  };

  function formatDatetime(datetimeString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    };
    return new Date(datetimeString).toLocaleString('en-US', options);
  }
  const participantsNoTeam =
    participants.filter((par) => par.consent_team_formation).length -
      teams.map((team) => team.participants.length).reduce((sum, count) => sum + count, 0) ===
    0;

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const removeFilter = (key, filter) => {
    const updatedFilters = filters[key].filter((f) => f !== filter);
    setFilters({ ...filters, [key]: updatedFilters });
  };

  const filterNames = Object.keys(filters).reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});

  useEffect(() => {
    // console.log('filters in useffect singlehackathon ' + JSON.stringify(filters));
    getSingleHackathon();
    fetchData();
  }, [debouncedFilters]);

  return (
    <Container className='bg-light pb-5 singleHackathon-wrapper' fluid>
      <Row className='pt-5 pb-3 px-5 d-flex align-items-center'>
        <Col>
          <Button className='btn-light btn-lg fw-bold fs-4 text-main-secondary' onClick={back}>
            <FontAwesomeIcon icon={faArrowLeft} className='fs-5 me-2 text-main-secondary' />
            Back
          </Button>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Button
            className={`${participantsNoTeam ? 'btn-prim me-2 disabled' : 'btn-prim me-2'}`}
            onClick={() => setGenerateTeamShow(true)}
          >
            Generate teams
          </Button>
          <GenerateTeams
            loading={loading}
            setLoading={setLoading}
            show={generateTeamShow}
            setShow={setGenerateTeamShow}
            getTeams={fetchData}
            hackathonId={hackathonParam.id}
          />

          <Button className='btn-light text-primary' onClick={handleCopyClick}>
            <FontAwesomeIcon icon={faLink} className=' me-2 fs-6' />
            Copy registration form link
          </Button>
          {alert.show && <Notification remove={showAlert} type={alert.type} msg={alert.msg} />}

          <Button className='btn-light text-danger' onClick={() => setDeleteModalShow(true)}>
            <FontAwesomeIcon icon={faTrashCan} className='text-danger fs-6 me-2' />
            Delete hackathon
          </Button>
          <DeleteHackathon
            deleteHackathon={deleteHackathon}
            setDeleteModalShow={setDeleteModalShow}
            show={deleteModalShow}
            loading={loading}
          />
        </Col>
      </Row>
      <Row className='d-flex ps-5'>
        <Col>
          <h1 className='display-4 d-flex text-break'>{singleHackathon.name}</h1>
        </Col>
        <ParticipantsColumnModal
          show={showColumnsModal}
          setFiltersModalShow={setColumnsModalShow}
          updateTableFields={updateTableFields}
          tableFields={tableFields}
        />
      </Row>
      <Row className='d-flex ps-5'>
        <Col>
          <p className='text-muted'>{formatDatetime(singleHackathon.date)}</p>
        </Col>
      </Row>
      <AllParticipants participants={participants} teams={teams} />
      {fi ? (
        <Row className='px-5 align-items-center'>
          <Row className='mb-3 align-items-center'>
            <Col className='d-flex'>
              <DropdownButton id='dropdown-basic-button' title='Bulk actions'>
                <Dropdown.Item
                  className={`text-primary ${
                    selectedParticipants.some((p) => !p.consent_team_formation) &&
                    'disabled text-muted'
                  }`}
                  onClick={() => openBulkModal('createTeam')}
                >
                  <FontAwesomeIcon icon={faPlus} className='me-2 icon' />
                  Create team
                </Dropdown.Item>
                <Dropdown.Item
                  className={`text-primary ${
                    (!selectedParticipants.length ||
                      selectedParticipants.some((p) => !p.consent_team_formation) ||
                      participantsNoTeam) &&
                    'disabled text-muted'
                  }`}
                  onClick={() => openBulkModal('assignToTeam')}
                >
                  <FontAwesomeIcon icon={faUserPlus} className='me-2 icon' />
                  Assign to team
                </Dropdown.Item>
                <Dropdown.Item
                  className={`text-primary ${
                    (!selectedParticipants.length ||
                      selectedParticipants.some((p) => !p.consent_team_formation)) &&
                    'disabled text-muted'
                  }`}
                  onClick={() => openBulkModal('dropFromTeam')}
                >
                  <FontAwesomeIcon icon={faUserMinus} className='me-2 icon' />
                  Drop from team
                </Dropdown.Item>
                <Dropdown.Item
                  className={`text-danger ${!selectedParticipants.length && 'disabled text-muted'}`}
                  onClick={() => openBulkModal('deleteParticipant')}
                >
                  <FontAwesomeIcon icon={faTrashCan} className='me-2 icon' />
                  Delete participant
                </Dropdown.Item>
              </DropdownButton>

              <BulkActModal
                showBulk={bulkActModalShow}
                setShowBulk={setBulkActModalShow}
                modalType={modalType}
                selectedParticipants={selectedParticipants}
                setSelectedParticipants={setSelectedParticipants}
                teams={teams}
                setTeams={setTeams}
                hackathonId={hackathonParam.id}
                getTeams={fetchData}
              />
            </Col>

            <Col className='d-flex justify-content-end align-items-center'>
              <div className='input-group d-flex' style={{ minWidth: '200px', maxWidth: '450px' }}>
                <span className='input-group-text bg-white '>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className='fs-6 ' />
                </span>
                <input
                  type='text'
                  className='form-control'
                  value={filters.search || ''}
                  placeholder='Search...'
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <Sorting filters={filters} setFilters={setFilters} />

              <Button variant='light' onClick={() => setShowFiltersModal(true)}>
                <FontAwesomeIcon icon={faSliders} className='me-2 text-primary' />
                Filters
              </Button>
              <Button className='btn-light' onClick={() => setColumnsModalShow(true)}>
                <FontAwesomeIcon icon={faChartColumn} className='fs-5 me-2 text-primary' />
                Columns
              </Button>
            </Col>
            <FilterModal
              show={showFiltersModal}
              setShow={setShowFiltersModal}
              filters={filters}
              setFilters={setFilters}
              nationalities={nationalities}
            />
          </Row>
          <div className='pt-0'>
            <hr className='pb-1 mt-1' />
          </div>
          <SelectedFiltersDisplay
            filters={filters}
            setFilters={setFilters}
            removeFilter={removeFilter}
            getKeyByValue={getKeyByValue}
            filterNames={filterNames}
            /*handleClearFilter={handleClearFilter}*/
          />

          <ParticipantsTeams
            filteredParticipants={participants}
            selectedFields={selectedParticipantFields}
            tableFields={tableFields}
            selectedParticipants={selectedParticipants}
            setSelectedParticipants={setSelectedParticipants}
            teams={teams}
            setTeams={setTeams}
            hackathonId={hackathonParam.id}
            getTeams={fetchData}
            filters={filters}
            setFilters={setFilters}
          />
        </Row>
      ) : (
        <SpinnerLoad />
      )}
    </Container>
  );
};
export default SingleHackathon;
