import React from 'react';
import { fieldValuesMap } from '../../data';
import { useGlobalContext } from '../../context';

const TableBody = ({
  filteredParticipants,
  selectedFields,
  selectedParticipants,
  setSelectedParticipants,
  checkBoxId,
  name,
}) => {
  const { handleShowDropdown } = useGlobalContext();

  const handleSelect = (participant) => {
    if (selectedParticipants && selectedParticipants.some((p) => p.id === participant.id)) {
      setSelectedParticipants(selectedParticipants.filter((p) => p.id !== participant.id));
    } else {
      setSelectedParticipants([...selectedParticipants, participant]);
    }
    handleShowDropdown(5);
  };

  // useEffect(() => {
  //   console.log('participant selected ', selectedParticipants);
  // }, [selectedParticipants]);

  return (
    <tbody>
      {filteredParticipants &&
        filteredParticipants.map((participant, index) => {
          if (!participant || typeof participant !== 'object') {
            return null;
          }
          const isChecked = selectedParticipants.some((p) => p.id === participant.id);
          return (
            <tr key={index}>
              <td key={-1}>
                <input
                  className='form-check-input m-0 p-0'
                  style={{ cursor: 'pointer' }}
                  type='checkbox'
                  id={`${checkBoxId}-${index}`}
                  onChange={() => handleSelect(participant)}
                  name={name}
                  checked={isChecked}
                />
              </td>
              {selectedFields.map((field, fieldIndex) => (
                <td key={fieldIndex}>
                  {fieldValuesMap[field]
                    ? fieldValuesMap[field][participant[field]] ?? participant[field]
                    : participant[field]}
                </td>
              ))}
            </tr>
          );
        })}
    </tbody>
  );
};

export default TableBody;
