import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import api from '../api/axiosConfig';
import backendUrl from '../config';
import Modal from 'react-bootstrap/Modal';
import { useGlobalContext } from '../context';
import Notification from '../components/alert/Notification';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const ResetPassword = () => {
  const { alert, showAlert } = useGlobalContext();

  const [resetPwModalShow, setResetPwModalShow] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const [errMessage, setErrMessage] = useState('');

  const [user, setUser] = useState({
    newpw: '',
    repeatpw: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const closeModal = () => {
    setResetPwModalShow(false);
    setErrMessage('');
  };

  const goToMain = () => {
    setTimeout(() => {
      navigate('/');
    }, 900);
  };

  const resetPw = async (e) => {
    e.preventDefault();

    if (user.newpw !== user.repeatpw) {
      setErrMessage('Password mismatch');
      return;
    }

    if (user.newpw.length < 8) {
      setErrMessage('Password must be at least 8 characters long');
      return;
    }

    if (user.newpw.length > 64) {
      setErrMessage('Password must be between 8 and 64 characters long');
      return;
    }

    if (!/\d/.test(user.newpw) || !/[a-zA-Z]/.test(user.newpw)) {
      setErrMessage('Password must contain both letters and numbers');
      return;
    }

    try {
      const response = await api.post(`${backendUrl}/auth/reset-password/`, {
        token: token,
        new_password: user.repeatpw,
      });
      if (response.status === 204) {
        setErrMessage('');
        closeModal();
        showAlert(true);
        goToMain();
      } else {
        console.log('oops');
        setErrMessage('Weak password');
      }
    } catch (err) {
      console.log('Error: ', err);
      setErrMessage(err.response.data.detail[0].msg);
    }
  };
  return (
    <>
      {alert.show && <Notification remove={showAlert} type={'success'} msg={'Password reset'} />}

      <Modal show={resetPwModalShow} className='mt-5 pt-5 w-100' backdrop='static'>
        <Modal.Header>
          <Modal.Title className='fw-bold fs-3 px-2'>Reset password</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-4'>
          <Form>
            <Form.Group controlId='formBasicNewPw' className='mb-2'>
              <FloatingLabel controlId='floatingNewPw' label='New password'>
                <Form.Control
                  type='password'
                  name='newpw'
                  placeholder='Password'
                  className='form-control-lg'
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group controlId='formBasicConfirmPw'>
              <FloatingLabel controlId='floatingRepeatPw' label='Confirm password'>
                <Form.Control
                  type='password'
                  name='repeatpw'
                  placeholder='Password'
                  className='form-control-lg'
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Form.Group>
          </Form>
          <Button type='submit' className='mt-3 mb-2 w-100 btn-lg btn-prim ' onClick={resetPw}>
            Set new password
          </Button>
          {errMessage && <p className='text-danger text-center pt-2 pb-0 mb-1'>{errMessage}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResetPassword;
