import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import GetCookie from '../../../hooks/getCookie';
// import api from '../../../api/axiosConfig';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import useAuthorizedRequest from '../../../api/axiosConfiguration';

const CreateTeam = ({
  selectedParticipants,
  setSelectedParticipants,
  hackathonId,
  setTeams,
  teams,
  closeModal,
  getTeams,
}) => {
  const [teamInfo, setTeamInfo] = useState({
    name: '',
    participants: [],
  });

  const makeAuthorizedRequest = useAuthorizedRequest();

  const handleCreateTeam = async () => {
    console.log('participants selected in create team ' + JSON.stringify(selectedParticipants));

    let tobeadded = [];
    if (selectedParticipants) {
      tobeadded = selectedParticipants.map(({ id }) => ({ id }));
      setTeamInfo((prevState) => ({ ...prevState, participants: tobeadded }));
    }
    console.log('teaminfo after operations ' + JSON.stringify(teamInfo));

    try {
      const response = await makeAuthorizedRequest(
        `/hackathons/${hackathonId}/teams`,
        { name: teamInfo.name, participants: tobeadded, warnings: [] },
        'post',
      );

      setTeamInfo({ ...teamInfo, participants: response.data });
      setTeams([...teams, teamInfo]);
      setSelectedParticipants([]);
    } catch (err) {
      console.log(err);
    }
    getTeams();
    closeModal();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Create Team</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <FloatingLabel label='Team name'>
          <Form.Control
            type='text'
            id='input1'
            value={teamInfo.name}
            onChange={(e) => setTeamInfo({ ...teamInfo, name: e.target.value })}
          />
        </FloatingLabel>
        <Row className='my-3'>
          <Col col={12} className='d-flex'>
            <Button variant='outline-dark' className='me-3 w-50 ' onClick={closeModal}>
              Cancel
            </Button>
            <Button className='w-50 btn-prim' onClick={handleCreateTeam}>
              Create team
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export default CreateTeam;
