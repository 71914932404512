import React, { Component } from 'react';
import FirstStep from './FirstStep';
import Finish from './Finish';
import Confirm from './Confirm';
import { AppContext } from '../../context';

export class CreateHackathon extends Component {
  static contextType = AppContext;

  state = {
    step: 1,
    title: '',
    date: '',
    response: null,
    errMessage: '',
    loading: false,
    copyShow: false,
  };

  setResponse = (response) => {
    this.setState({ response: response });
  };

  setErrMessage = (errMessage) => {
    this.setState({ errMessage: errMessage });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  handleCopyClick = () => {
    this.context.showAlert(true);
    navigator.clipboard.writeText(this.state.response);
    console.log('copy on ' + JSON.stringify(this.context.alert));
  };

  inputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  handleConfirmation = async () => {
    this.setState({ loading: true });
    const newHackathon = this.state;

    try {
      const response = await this.props.addHackathon(newHackathon);

      this.setResponse(response.survey_link);

      this.nextStep();
    } catch (err) {
      console.error(err);
    }
    this.setState({ loading: false });
  };

  render() {
    const { step } = this.state;
    const { title, date, errMessage, response, loading } = this.state;
    const values = { title, date, errMessage, response, loading };
    const { showAlert, alert } = this.context;
    const context = { alert, showAlert };

    switch (step) {
      case 1:
        return (
          <FirstStep
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            values={values}
            setErrMessage={this.setErrMessage}
          />
        );

      case 2:
        return (
          <Confirm
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            prevStep={this.prevStep}
            values={values}
            addHackathon={this.handleConfirmation}
          />
        );
      case 3:
        return (
          <Finish
            handleCopyClick={this.handleCopyClick}
            prevStep={this.prevStep}
            values={values}
            context={context}
          />
        );
    }
  }
}

export default CreateHackathon;
