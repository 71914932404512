import React, { useState, useContext } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [authModalShow, setAuthModalShow] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [targetItem, setTargetItem] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    msg: '',
    type: '',
  });

  const handleShowDropdown = (targetItem) => {
    setTargetItem(targetItem);
    const lastTriggeredTime = localStorage.getItem(targetItem);
    const currentTime = new Date().getTime();

    if (!lastTriggeredTime || currentTime - lastTriggeredTime >= 3600000) {
      setDropdown(true);
      localStorage.setItem(targetItem, currentTime);
    }
    setTimeout(() => {
      setDropdown(false);
      setTargetItem(null);
    }, 5000);
  };

  const showAlert = (show = false, type = '', msg = '') => {
    setAlert({ show, type, msg });
  };

  return (
    <AppContext.Provider
      value={{
        alert,
        showAlert,
        authModalShow,
        setAuthModalShow,
        dropdown,
        setDropdown,
        targetItem,
        setTargetItem,
        handleShowDropdown,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
