import React from 'react';

const StepFive = () => {
  return (
    <>
      <h4>Manage suggestions</h4>
      <p className='mt-3'>
        To support startup creation at the hackathon, those participants with entrepreneurial
        motivation and startup ideas (proposers) must get together in teams with participants who
        also have entrepreneurial motivation (joiners).
        <br />
        <br />
        To help them find each other at the hackathon, proposers with startup ideas should pitch
        first, and mention during the pitch, that their ideas are startup ideas. After their
        pitches, all participants should be notified that if they have entrepreneurial motivation,
        they should join the teams of the proposers who also have entrepreneurial motivation.
        <br />
        <br />
        To help proposers identify who are the joiners with entrepreneurial motivation, there can
        also be a label on the joiners` name tags that represents their entrepreneurial motivation,
        such as “entrepreneur”. Additionally, you can suggest to the proposers a list of joiners by
        setting up together potential teams below.
      </p>
      <p className='fw-bold mt-4 mb-1'>How to handle participants who don`t find a team?</p>
      <p>
        When joiners don`t like any of the ideas before the hackathon, they should be invited to
        pitch their own ideas. If they don`t find teams they like during the hackathon, they should
        be encouraged to join a team that works on an idea of a topic of their interest. They should
        also be reminded of the multiple benefits of the hackathon.
      </p>
      <p className='fw-bold mt-4 mb-1'>How to manage teams who already have a product?</p>
      <p>
        Fully formed teams who already have a product should be encouraged to refine it. This could
        take form in adding features to it, receiving feedback from a mentor and testing it with
        stakeholders at the hackathon.
      </p>
    </>
  );
};

export default StepFive;
