import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DeleteConfirmation = ({ show, setDeleteModalShow, handleDeleteTeam, teamToDelete }) => {
  return (
    <Modal show={show} className='mt-5 pt-5 w-100 ' backdrop='static'>
      <Modal.Header closeButton onHide={() => setDeleteModalShow(false)}>
        <Modal.Title className='fw-bold fs-3 px-2'>Disband team</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <p className='text-muted'>
          {' '}
          Are you sure you want to disband this team? This action cannot be undone. These team
          members won`t be assigned to the team anymore.
        </p>
      </Modal.Body>
      <Row className='mt-1 mb-4 px-4'>
        <Col className='d-flex gap-2'>
          <Button variant='outline-dark' className='w-50' onClick={() => setDeleteModalShow(false)}>
            Cancel
          </Button>
          <Button variant='danger' className='w-50' onClick={() => handleDeleteTeam(teamToDelete)}>
            Disband team
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteConfirmation;
