import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSignIn } from 'react-auth-kit';
import api from '../../api/axiosConfig';
import backendUrl from '../../config';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ setIsLogin, closeModal, nextStep }) => {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState('');

  const goToDashboard = () => {
    setTimeout(() => {
      navigate('/dashboard');
    }, 50);
  };

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const login = async (e) => {
    e.preventDefault();
    if (!user.email || !user.password) {
      setErrMessage('Please fill all fields');
      return;
    }
    if (!user.email.includes('@')) {
      setErrMessage(['Please enter a valid email address']);
      return;
    }

    try {
      const response = await api.post(`${backendUrl}/auth/sign-in`, {
        email: user.email,
        password: user.password,
      });

      const token = response.data.token;
      if (response.status === 200) {
        signIn({
          token: token,
          expiresIn: 60 * 8,
          tokenType: 'Bearer',
          authState: { email: user.email },
        });

        setErrMessage('');
        closeModal();
        goToDashboard();
      } else {
        console.log('oops');
        setErrMessage('Wrong email or password');
      }
    } catch (err) {
      console.log('Error: ', err);
      if (Array.isArray(err.response.data.detail)) {
        setErrMessage(err.response.data.detail[0].msg);
      } else {
        setErrMessage(err.response.data.detail);
      }
    }
  };

  return (
    <>
      <Form>
        <Form.Group controlId='formBasicEmail'>
          <FloatingLabel controlId='floatingInput' label='Email address' className='mb-3'>
            <Form.Control
              type='email'
              name='email'
              placeholder='Enter email'
              className='form-control-lg'
              onChange={handleInputChange}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId='formBasicPassword' className='mt-2'>
          <FloatingLabel controlId='floatingPassword' label='Password'>
            <Form.Control
              type='password'
              name='password'
              placeholder='Enter password'
              className='form-control-lg'
              onChange={handleInputChange}
            />
          </FloatingLabel>
        </Form.Group>
        <p
          className='text-center text-main-secondary mt-2 mb-0 pt-1 w-50 mx-auto'
          style={{ cursor: 'pointer' }}
          onClick={nextStep}
        >
          Forgot password?
        </p>
      </Form>
      <Button type='submit' className='my-3 w-100 btn-lg btn-prim ' onClick={login}>
        Log in
      </Button>
      {errMessage && <p className='text-danger text-center'>{errMessage}</p>}

      <p
        className='text-center text-main-secondary mb-1 w-50 mx-auto'
        style={{ cursor: 'pointer' }}
        onClick={() => setIsLogin(false)}
      >
        Don`t have an account?
      </p>
    </>
  );
};

export default LoginForm;
