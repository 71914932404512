import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SpinnerLoad from '../../loading/SpinnerLoad';

const DeleteHackathon = ({ show, setDeleteModalShow, deleteHackathon, loading }) => {
  return (
    <Modal show={show} className='mt-5 pt-5 w-100 ' onHide={() => setDeleteModalShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Delete hackathon</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <p className='text-muted'> Are you sure you want to delete the hackathon?</p>
      </Modal.Body>

      <Row className='mt-1 mb-4 px-4'>
        <Col className='d-flex gap-2'>
          {loading ? (
            <SpinnerLoad />
          ) : (
            <>
              <Button
                variant='outline-dark'
                className='w-50'
                onClick={() => setDeleteModalShow(false)}
              >
                Cancel
              </Button>
              <Button variant='danger' className='w-50' onClick={deleteHackathon}>
                Delete
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteHackathon;
