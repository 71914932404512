import axios from 'axios';
import backendUrl from '../config';
import { useNavigate } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';
import GetCookie from '../hooks/getCookie';
// export default axios.create({

const useAuthorizedRequest = () => {
  const instance = axios.create({
    baseURL: backendUrl,
    headers: {
      'ngrok-skip-browser-warning': 'true',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  const signOut = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    signOut();
    navigate('/');
  };

  const makeAuthorizedRequest = async (url, data = {}, method = 'post') => {
    const token = GetCookie('_auth');

    try {
      const response = await instance({
        method: method,
        url: url,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Request failed:', error);

      if (error.response && error.response.status === 403) {
        logout();
      }

      throw error;
    }
  };

  return makeAuthorizedRequest;
};

export default useAuthorizedRequest;
