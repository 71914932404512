import React from 'react';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import CreateTeam from './CreateTeam';
import DeleteParticipant from './DeleteParticipant';
import AssignToTeam from './AssignToTeam';
import DeleteHackathon from './DeleteHackathon';
import DropFromTeam from './DropFromTeam';

const BulkActModal = ({
  showBulk,
  setShowBulk,
  modalType,
  selectedParticipants,
  setSelectedParticipants,
  hackathonId,
  teams,
  setTeams,
  getTeams,
}) => {
  const closeModal = () => {
    setShowBulk(false);
  };

  // const modifyTeam = (action) => {
  //   if (action === 'assign'){
  //     setTeams()
  //   }
  //   else {

  //   }
  // }

  const renderModalContent = () => {
    switch (modalType) {
      case 'createTeam':
        return (
          <CreateTeam
            selectedParticipants={selectedParticipants}
            setSelectedParticipants={setSelectedParticipants}
            hackathonId={hackathonId}
            setTeams={setTeams}
            teams={teams}
            closeModal={closeModal}
            getTeams={getTeams}
          />
        );
      case 'deleteParticipant':
        return (
          <DeleteParticipant
            teams={teams}
            getTeams={getTeams}
            closeModal={closeModal}
            hackathonId={hackathonId}
            selectedParticipants={selectedParticipants}
            setSelectedParticipants={setSelectedParticipants}
          />
        );
      case 'assignToTeam':
        return (
          <AssignToTeam
            teams={teams}
            setTeams={setTeams}
            closeModal={closeModal}
            hackathonId={hackathonId}
            getTeams={getTeams}
            selectedParticipants={selectedParticipants}
            setSelectedParticipants={setSelectedParticipants}
          />
        );
      case 'dropFromTeam':
        return (
          <DropFromTeam
            teams={teams}
            setTeams={setTeams}
            getTeams={getTeams}
            closeModal={closeModal}
            hackathonId={hackathonId}
            selectedParticipants={selectedParticipants}
            setSelectedParticipants={setSelectedParticipants}
          />
        );
      case 'deleteHackathon':
        return <DeleteHackathon />;

      default:
        return null;
    }
  };

  return (
    <Modal show={showBulk} className='p-5 modal-dialog-centered' onHide={closeModal}>
      {renderModalContent()}
    </Modal>
  );
};

export default BulkActModal;
