import React from 'react';

const StepFour = () => {
  return (
    <>
      <h4>Selecting participants</h4>
      <p className='mt-3'>
        To select participants for the hackathon, prioritize those who are motivated to create a
        startup and have a startup idea.
        <br />
        <br />
        Select the ideas that have a good problem-to-solution match, a clear target audience and a
        reasonable project development plan for the hackathon.
        <br />
        <br />
        You should also allow for participants who have an idea that is not a startup idea but that
        are motivated to create a startup.
        <br />
        Their idea could potentially develop into a startup idea during the hackathon.
        <br />
        <br />
        Finally, look into the participants who will not bring any ideas to the hackathon (joiners).
        <br />
        Make sure there are enough participants who could fill in the roles needed by the
        participants with ideas.
      </p>
    </>
  );
};

export default StepFour;
