import React, { useState } from 'react';
// import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import TableBody from './TableBody';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
// import GetCookie from '../../hooks/getCookie';
// import api from '../../api/axiosConfig';
import DeleteConfirmation from '../modals/DeleteConfirmation';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useAuthorizedRequest from '../../api/axiosConfiguration';

const ParticipantsTeams = ({
  filteredParticipants,
  selectedFields,
  tableFields,
  selectedParticipants,
  setSelectedParticipants,
  teams,
  setTeams,
  hackathonId,
  getTeams,
}) => {
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);

  const makeAuthorizedRequest = useAuthorizedRequest();

  const trashClicked = (teamID) => {
    setTeamToDelete(teamID);
    setDeleteModalShow(true);
  };

  const handleDeleteTeam = async (teamId) => {
    try {
      await makeAuthorizedRequest(`/hackathons/${hackathonId}/teams/${teamId}`, {}, 'delete');

      const updatedTeams = teams.filter((team) => team.id !== teamId);
      setTeams(updatedTeams);
      setSelectedParticipants([]);
    } catch (err) {
      console.log(err);
    } finally {
      getTeams();
      setDeleteModalShow(false);
    }
  };

  function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
  const mx = 12;
  const tableFieldsCount = tableFields.length;
  const screenWidth = window.screen.width;

  let tableMaxWidth = tableFieldsCount * convertRemToPixels(mx);
  let tableWidth = 'max-content';
  if (tableMaxWidth < screenWidth) {
    tableMaxWidth = screenWidth;
    tableWidth = '100%';
  }

  const allParticipants = [];
  const noConsentParticipants = [];
  const teamsParticipants = [];

  const participantsInTeamsList = teams.flatMap((team) =>
    team && team.participants
      ? team.participants.map((participant) =>
          participant && participant.id ? participant.id : null,
        )
      : [],
  );

  filteredParticipants.forEach((participant) => {
    if (!participant) {
      return;
    }
    if (participantsInTeamsList.includes(participant.id)) {
      teamsParticipants.push(participant);
    } else {
      if (participant.consent_team_formation) {
        allParticipants.push(participant);
        return;
      }
      noConsentParticipants.push(participant);
    }
  });

  // console.log('Participants all ' + JSON.stringify(allParticipants));
  // console.log('NOCONSENT all ' + JSON.stringify(noConsentParticipants));

  const renderTooltip = (props, warning) => {
    let warningtext = '';
    switch (warning) {
      case 'no_idea_owner':
        warningtext = 'No idea owner';
        break;
      case 'no_programmer':
        warningtext = 'No programmer';
        break;
      case 'no_designer':
        warningtext = 'No designer';
        break;
      case 'too_small':
        warningtext = 'Team size too small';
        break;
      case 'too_large':
        warningtext = 'Team size too large';
        break;
    }

    return (
      <Tooltip id='button-tooltip' {...props} className='bg-primary'>
        {warningtext}
      </Tooltip>
    );
  };

  return (
    <Accordion
      defaultActiveKey={['-2', '-1'].concat(teams.map((t) => JSON.stringify(t.id)))}
      alwaysOpen
    >
      {allParticipants.length === 0 && noConsentParticipants.length === 0 && teams.length === 0 && (
        <h4 className='text-muted m-1'>Nothing here yet...</h4>
      )}
      {allParticipants.length > 0 && (
        <Accordion.Item className='' eventKey='-2'>
          <Accordion.Header className=''>
            <Col>
              <h4 className='text-primary'>All participants</h4>
            </Col>
            <Col className='text-end'></Col>
          </Accordion.Header>
          <Accordion.Body>
            <div className='participants-table'>
              {tableFields.length > 0 ? (
                <>
                  <Table
                    striped
                    hover
                    style={{ width: tableWidth, maxWidth: tableMaxWidth, minWidth: tableMaxWidth }}
                  >
                    <thead className=''>
                      <tr>
                        <th></th>
                        {tableFields.map((header, index) => (
                          <th key={index}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <TableBody
                      filteredParticipants={allParticipants}
                      selectedFields={selectedFields}
                      selectedParticipants={selectedParticipants}
                      setSelectedParticipants={setSelectedParticipants}
                      checkBoxId={'participants-checkbox'}
                      name={'participantCheckBox'}
                    />
                  </Table>
                </>
              ) : (
                <p className='text-muted'> No table fields selected</p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {filteredParticipants.length > 0 &&
        teams?.map((team, i) => {
          return (
            <Accordion.Item eventKey={JSON.stringify(team.id)} key={i + 1} className=''>
              <Accordion.Header className=''>
                <Col>
                  <h4 className='text-primary'>
                    {team.name}
                    {team.warnings &&
                      team.warnings.map((warning, i) => {
                        return (
                          <OverlayTrigger
                            key={i}
                            placement='top'
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) => renderTooltip(props, warning)}
                          >
                            <FontAwesomeIcon
                              icon={faTriangleExclamation}
                              className='text-danger ms-2 text-opacity-80'
                            />
                          </OverlayTrigger>
                        );
                      })}
                  </h4>
                </Col>
                <Col className='text-end'></Col>
              </Accordion.Header>
              <Accordion.Body className='px-4'>
                <div className='participants-table'>
                  {tableFields.length > 0 ? (
                    <Table
                      striped
                      hover
                      style={{
                        width: tableWidth,
                        maxWidth: tableMaxWidth,
                        minWidth: tableMaxWidth,
                      }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          {tableFields.map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <TableBody
                        filteredParticipants={teams[i].participants.filter((p) => !p.incomplete)}
                        selectedFields={selectedFields}
                        selectedParticipants={selectedParticipants}
                        setSelectedParticipants={setSelectedParticipants}
                        checkBoxId={'teams-checkbox'}
                        name={'teamCheckBox'}
                      />
                    </Table>
                  ) : (
                    <p className='text-muted'> No table fields selected</p>
                  )}
                </div>
                <Row className='text-end'>
                  <Col className='m-2 pt-2 text-danger'>
                    <div style={{ cursor: 'pointer' }} onClick={() => trashClicked(team.id)}>
                      Disband team
                      <FontAwesomeIcon icon={faTrashCan} className='text-danger ms-1 fs-5 ' />
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}

      {noConsentParticipants.length > 0 && (
        <Accordion.Item className='' eventKey='-1'>
          <Accordion.Header className=''>
            <Col>
              <h4 className='text-muted'>Non-consented participants</h4>
            </Col>
          </Accordion.Header>
          <Accordion.Body>
            <div className='participants-table'>
              {tableFields.length > 0 ? (
                <Table
                  striped
                  hover
                  style={{ width: tableWidth, maxWidth: tableMaxWidth, minWidth: tableMaxWidth }}
                >
                  <thead className=''>
                    <tr>
                      <th></th>
                      {tableFields.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <TableBody
                    filteredParticipants={noConsentParticipants}
                    selectedFields={selectedFields}
                    selectedParticipants={selectedParticipants}
                    setSelectedParticipants={setSelectedParticipants}
                    checkBoxId={'no-contsent-participants-checkbox'}
                    name={'no-consent-participantCheckBox'}
                  />
                </Table>
              ) : (
                <p className='text-muted'> No table fields selected</p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      )}
      <DeleteConfirmation
        show={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
        handleDeleteTeam={handleDeleteTeam}
        teamToDelete={teamToDelete}
      />
    </Accordion>
  );
};

export default ParticipantsTeams;
