import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserGroup,
  faPersonCircleExclamation,
  faCircleCheck,
  faPersonCircleMinus,
} from '@fortawesome/free-solid-svg-icons';

const AllParticipants = ({ participants, teams }) => {
  const completeTeams = teams.filter((team) => team.warnings && team.warnings.length === 0).length;
  const incompleteTeams = teams.filter((team) => team.warnings && team.warnings.length > 0).length;
  const participantsNoTeam = Math.abs(
    participants.length -
      teams.map((team) => team.participants.length).reduce((sum, count) => sum + count, 0),
  );

  return (
    <Row className='px-4 pb-1 d-flex justify-content-center'>
      <Col className='column' col={3} xs>
        <FontAwesomeIcon icon={faUserGroup} className='fs-3' />
        <h4>Total participants: {participants.length}</h4>
      </Col>
      <Col col={3} className='column' xs>
        <FontAwesomeIcon icon={faCircleCheck} className='fs-3' />
        <h4>Complete teams: {completeTeams}</h4>
      </Col>
      <Col col={3} className='column' xs>
        <FontAwesomeIcon icon={faPersonCircleExclamation} className='fs-3' />
        <h4>Incomplete teams: {incompleteTeams}</h4>
      </Col>
      <Col col={3} className='column' xs>
        <FontAwesomeIcon icon={faPersonCircleMinus} className='fs-3' />
        <h4>No team: {participantsNoTeam}</h4>
      </Col>
    </Row>
  );
};

export default AllParticipants;
