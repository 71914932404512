import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSignIn } from 'react-auth-kit';
import api from '../../api/axiosConfig';
import backendUrl from '../../config';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const SignUpForm = ({ setIsLogin, closeModal }) => {
  const signIn = useSignIn();

  const [errMessage, setErrMessage] = useState([]);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const signUp = async (e) => {
    e.preventDefault();

    if (!user.email || !user.email.includes('@')) {
      setErrMessage(['Please enter a valid email address']);
      return;
    }

    if (user.password.length < 8) {
      setErrMessage(['Password must be at least 8 characters long']);
      return;
    }

    if (user.password.length > 64) {
      setErrMessage(['Password must be between 8 and 64 characters long']);
      return;
    }

    if (!/\d/.test(user.password) || !/[a-zA-Z]/.test(user.password)) {
      setErrMessage(['Password must contain both letters and numbers']);
      return;
    }

    try {
      const response = await api.post(`${backendUrl}/auth/sign-up`, {
        email: user.email,
        password: user.password,
      });

      if (response.status === 200) {
        signIn({
          token: response.data.token,
          expiresIn: 60 * 8,
          tokenType: 'Bearer',
          authState: { email: user.email },
        });
        setErrMessage('');
        closeModal();
      } else {
        console.log('Status code: ' + response.status);
        setErrMessage(['Wrong email format or weak password']);
      }
    } catch (err) {
      setErrMessage(
        Array.isArray(err.response.data.detail)
          ? err.response.data.detail.map((detail) => {
              return detail.msg;
            })
          : [err.response.data.detail],
      );
    }
  };

  return (
    <>
      <Form>
        <Form.Group controlId='formBasicEmail'>
          <FloatingLabel controlId='floatingInput' label='Email address'>
            <Form.Control
              type='email'
              name='email'
              placeholder='Enter email'
              className='form-control-lg'
              onChange={handleInputChange}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId='formBasicPassword' className='mt-2 pt-2'>
          <FloatingLabel controlId='floatingPassword' label='Password'>
            <Form.Control
              type='password'
              name='password'
              placeholder='Enter password'
              className='form-control-lg'
              onChange={handleInputChange}
            />
          </FloatingLabel>
        </Form.Group>
      </Form>
      <Button type='submit' className='my-3 w-100 btn-lg btn-prim' onClick={signUp}>
        Sign up
      </Button>
      {errMessage &&
        errMessage.map((err, i) => {
          return (
            <p key={i} className='text-danger text-center'>
              {err}
            </p>
          );
        })}

      {/* <p className='text-center text-muted '>
        By signing up, you agree to our
        <a href='/' className='text-main-secondary'>
          {' '}
          Terms of Service and Privacy Policy
        </a>
      </p> */}

      <p
        className='text-center text-main-secondary mb-1 w-50 mx-auto'
        style={{ cursor: 'pointer' }}
        onClick={() => setIsLogin(true)}
      >
        Already have an account?
      </p>
    </>
  );
};

export default SignUpForm;
