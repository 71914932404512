import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import api from '../../api/axiosConfig';
import backendUrl from '../../config';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const ResetPassword = ({ nextStep }) => {
  const [errMessage, setErrMessage] = useState('');

  const [user, setUser] = useState({
    email: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const sendMail = async (e) => {
    e.preventDefault();

    if (!user.email.length) {
      setErrMessage('Enter email');
      return;
    }
    try {
      const response = await api.post(`${backendUrl}/auth/password-recovery/${user.email}`);
      console.log('Response from sendmail ' + JSON.stringify(response));

      if (response.status === 204) {
        setErrMessage('');
        nextStep();
      }
    } catch (err) {
      console.log('Error: ', err);
      setErrMessage(err.response.data.detail[0].msg);
    }
  };
  return (
    <>
      <Form>
        <Form.Group controlId='formBasicEmail'>
          <FloatingLabel controlId='floatingInput' label='Email address'>
            <Form.Control
              type='email'
              name='email'
              placeholder='Enter email'
              className='form-control-lg'
              onChange={handleInputChange}
            />
          </FloatingLabel>
        </Form.Group>
      </Form>
      <Button type='submit' className='my-3 w-100 btn-lg btn-prim ' onClick={sendMail}>
        Send reset link
      </Button>
      {errMessage && <p className='text-danger text-center mb-1'>{errMessage}</p>}
    </>
  );
};

export default ResetPassword;
