import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import GetCookie from '../../../hooks/getCookie';
// import api from '../../../api/axiosConfig';
import useAuthorizedRequest from '../../../api/axiosConfiguration';

const AssignToTeam = ({
  teams,
  closeModal,
  selectedParticipants,
  setSelectedParticipants,
  setTeams,
  hackathonId,
  getTeams,
}) => {
  const [selectedTeam, setSelectedTeam] = useState(-1);

  const handleTeamSelection = (event) => {
    const selectedTeamId = parseInt(event.target.value, 10);
    setSelectedTeam(selectedTeamId);
  };

  // const token = GetCookie('_auth');
  const makeAuthorizedRequest = useAuthorizedRequest();

  const addToTeam = async () => {
    if (selectedTeam === -1) {
      return;
    }
    const modifiedTeams = [...teams];

    const teamIndex = modifiedTeams.findIndex((team) => team.id === selectedTeam);

    const originalTeam = modifiedTeams[teamIndex];
    console.log('Original team : ' + JSON.stringify(originalTeam));

    let PreviousparticipantIds = originalTeam.participants;
    if (originalTeam.participants) {
      PreviousparticipantIds = PreviousparticipantIds.map(({ id }) => ({ id }));
    }
    let tobeAddedIds = selectedParticipants;
    if (tobeAddedIds) {
      tobeAddedIds = tobeAddedIds.map(({ id }) => ({ id }));
    }

    const modifiedTeam = {
      name: originalTeam.name,
      participants: [...PreviousparticipantIds, ...tobeAddedIds],
      warnings: originalTeam.warnings,
    };

    try {
      await makeAuthorizedRequest(
        `/hackathons/${hackathonId}/teams/${selectedTeam}`,
        {
          participants: modifiedTeam.participants,
          name: modifiedTeam.name,
          warnings: modifiedTeam.warnings,
        },
        'patch',
      );
      // await api.patch(
      //   `/hackathons/${hackathonId}/teams/${selectedTeam}`,
      //   {
      //     participants: modifiedTeam.participants,
      //     name: modifiedTeam.name,
      //     idea: modifiedTeam.idea,
      //     warnings: modifiedTeam.warnings,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   },
      // );

      modifiedTeams[teamIndex] = modifiedTeam;
      setTeams(modifiedTeams);
      setSelectedParticipants([]);
    } catch (err) {
      console.log(err);
    } finally {
      getTeams();
    }
    closeModal();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold fs-3 px-2'>Assign to team</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        <h4>Choose Team</h4>
        <Row className='my-4 py-1'>
          <Col>
            <select
              className='form-select'
              aria-label='Default select example'
              value={selectedTeam}
              onChange={handleTeamSelection}
            >
              <option value='-1'>Choose team</option>
              {teams.map((team) => {
                let i = 110;
                return (
                  <option key={team.id || i++} value={team.id}>
                    {team.name}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row className='my-3'>
          <Col className='d-flex'>
            <Button variant='outline-dark' className='me-3 w-50 ' onClick={closeModal}>
              Cancel{' '}
            </Button>
            <Button
              className={`w-50 ${selectedTeam > -1 ? 'btn-prim' : 'disabled'}`}
              onClick={() => addToTeam()}
            >
              Assign to team
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export default AssignToTeam;
