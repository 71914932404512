import React from 'react';
import Container from 'react-bootstrap/Container';

const Footer = () => {
  return (
    <footer className='py-4 mt-1  footer-bg d-flex w-100'>
      <Container className='text-center py-2'>
        <p className=' text-white'>Copyright &copy; 2023 Ultimate Hackathon</p>
      </Container>
    </footer>
  );
};

export default Footer;
