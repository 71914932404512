import React from 'react';

const StepThree = () => {
  return (
    <>
      <h4>Post-hackathon support</h4>
      <p className='mt-3'>
        To move forward from a hackathon project into a startup project, hackathon awards should
        support project development, market validation, and finding a valid business plan. Use the
        judging criteria to identify what are the needs of each team and what would be the best
        award for them according to their needs.
      </p>
      <p className='fw-bold mt-4 mb-1'>Awards for project development </p>
      <p>
        <b>Cash rewards</b>: Provide teams with a sufficient amount of funding to cover their
        project development expenses.
        <br />
        <br />
        <b>Investment</b>: Invite investors to the hackathon who are interested in finding projects
        to fund. Participants should be informed there are investors present at the hackathon so
        they can approach them.
        <br />
        <br />
        <b>Tickets to incubators and accelerators</b>: Incubators and accelerators offer monetary
        prizes, networking opportunities, and feedback to teams.
        <br />
        <br />
        <b>Sponsor awards</b>: Sponsors can set up challenges and give awards to teams that work on
        them. That way, sponsors can solve issues related to their companies while providing a home
        for their projects.
      </p>
      <p className='fw-bold mt-4 mb-1'>Awards for market validation </p>
      <p>
        <b>Stands at entrepreneurial fairs</b>: At entrepreneurial fairs, teams can promote their
        projects and receive feedback from investors and stakeholders.
        <br />
        <br />
        <b>Marketing consultancy</b>: Teams who need to receive feedback from potential users can
        benefit from learning how to reach and connect with their audience through marketing
        consultancy sessions.
      </p>
      <p className='fw-bold mt-4 mb-1'>Awards for developing a business plan </p>
      <p>
        <b>Mentoring</b>: Feedback can be provided in the form of a mentorship program with a
        specific number of hours where mentors revise the monetization strategy of the teams.
        <br />
        <br />
        Teams who did not win any awards, or teams that would like to continue working on their
        projects after the hackathon ends should be directed towards:
        <br />
        <br />
        <b>Crowdfunding</b>: Crowdfunding is an accessible tool for teams to raise funds and get
        market validation. Popular crowdfunding platforms include Indiegogo and Kickstarter.
        <br />
        <br />
        <b>Incubators/accelerators</b>: Teams who would like to continue working on their projects
        should be advised to apply for incubators if they want to receive long-term support.
        However, teams looking to quickly solve immediate challenges should opt for accelerators.
        <br />
        <br />
        <b>Angel investors</b>: Angel investors provide financial resources, mentorship, expertise,
        and access to networks.
        <br />
        <br />
        <b>App store upload</b>: Participants should upload their projects onto an online app store
        to test how many downloads their projects would receive. The number of downloads and the
        feedback given by the users can help them develop their projects further.
        <br />
        <br />
        <b>Social media advertising</b>: In addition to advertising the teams` projects on the
        hackathon website and social media platforms, teams should also advertise their projects on
        their own social media profiles to receive feedback.
        <br />
        <br />
        <b>You can also share this list of business helping sources with participants:</b>
        <br />
        <a className='fw-bold' href='https://investinestonia.com/business-opportunities/'>
          Invest in Estonia
        </a>
        : Find resources based on the domain of your project
        <br />
        <a
          className='fw-bold'
          href='https://learn.e-resident.gov.ee/hc/en-us/articles/8910003131921-How-to-gain-support-for-your-business-in-Estonia'
        >
          E-residency
        </a>{' '}
        : Find grants, accelerators, incubators, competitions and counseling.
        <br />
        <a
          className='fw-bold'
          href='https://www.eesti.ee/en/doing-business/financing-a-company/financing-a-startup-company'
        >
          Eesti.ee
        </a>{' '}
        : Find different options for financing for your project.
        <br />
        <a className='fw-bold' href='https://startupestonia.ee/startup-ecosystem/service-providers'>
          Startup Estonia
        </a>{' '}
        : Find different public service providers that support early stage startups.
      </p>
    </>
  );
};

export default StepThree;
