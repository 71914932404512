import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Notification from '../alert/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Confirm extends Component {
  handleCopyClick = () => {
    this.props.handleCopyClick();
  };
  // handleAlert = () => {
  //   this.props.showAlert();
  // }
  render() {
    const {
      values: { response },
      context: { alert, showAlert },
    } = this.props;

    return (
      <>
        {alert.show && <Notification remove={showAlert} type={'primary'} msg={'Link copied'} />}
        <Container className='text-main-dark'>
          <Row>
            <h3 className='fw-bold text-main-dark'>Your hackathon is ready</h3>

            <Row>
              <Col className='d-flex align-items-center my-2'>
                <form className='w-100'>
                  <label htmlFor='text' className='form-label fw-light'>
                    Hackathon survey link
                  </label>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control text-primary'
                      value={response}
                      disabled
                      readOnly
                    />
                    <div className='input-group-text m-0 p-0'>
                      <Button className='btn-prim' onClick={this.handleCopyClick}>
                        <FontAwesomeIcon className='fs-5' icon={faCopy} />
                      </Button>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </Row>
        </Container>
      </>
    );
  }
}

export default Confirm;
