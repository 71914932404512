import React, { useState } from 'react';
// import Modal from 'react-bootstrap/Modal';
import { tableHeadersAsList, COLUMN_GROUPS } from '../../data';
import Offcanvas from 'react-bootstrap/Offcanvas';
import TableColumns from './columns/TableColumns';

const ParticipantsColumnModal = ({ show, setFiltersModalShow, updateTableFields, tableFields }) => {
  const [selectAll, setSelectAll] = useState(false);
  const handleFieldChange = (subGroup, f) => {
    let updatedFields = [...tableFields];
    const actualColumns = COLUMN_GROUPS[subGroup][f]; // first name, last name

    if (updatedFields.some((field) => actualColumns.includes(field))) {
      const ids = actualColumns.map((co) => updatedFields.indexOf(co));
      // console.log("Indexid on" + JSON.stringify(ids))   // 0, 1

      for (let i = 0; i < ids.length; i++) {
        const target = updatedFields.findIndex((el) => actualColumns.includes(el));
        updatedFields.splice(target, 1);
      }
    } else {
      const indexes = actualColumns.map((co) => tableHeadersAsList.indexOf(co));

      for (const index of indexes) {
        const suitable = updatedFields.findIndex(
          (element) => tableHeadersAsList.indexOf(element) > index,
        );

        suitable === -1
          ? updatedFields.splice(updatedFields.length, 0, tableHeadersAsList[index])
          : updatedFields.splice(suitable, 0, tableHeadersAsList[index]);
      }
    }
    const allSelected = updatedFields.length === tableHeadersAsList.length;
    setSelectAll(allSelected);
    updateTableFields(updatedFields);
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      updateTableFields([...tableHeadersAsList]);
    } else {
      updateTableFields([]);
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={() => setFiltersModalShow(false)} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='mt-4 ms-4 mb-3 fw-bolder'>Select columns</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <TableColumns
            handleFieldChange={handleFieldChange}
            tableFields={tableFields}
            updateTableFields={updateTableFields}
            handleSelectAllChange={handleSelectAllChange}
            selectAll={selectAll}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ParticipantsColumnModal;
