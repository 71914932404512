import React from 'react';

const StepOne = () => {
  return (
    <>
      <h4>Set up the hackathon website</h4>
      <p className='mt-3'>
        To support the entrepreneurial goals of participants at hackathons, include the main purpose
        of the hackathon, the judging criteria, awards, code of conduct and the IP regulations on
        the hackathon website. This allows participants to evaluate if the goal of the hackathon and
        the awards align with their startup ideas and what they should prioritize for a higher
        chance of winning. Remember to include any other information about how you are supporting
        startup creation at the hackathon, for example, if there are investors present.
      </p>
      <p className='fw-bold mt-4 mb-1'>Hackathon purpose </p>
      <p>
        Use the statement below to explain the purpose of the hackathon on your website:
        <br />
        <br />
        Hackathons are 48-hour events where participants gather in teams to develop projects. We aim
        to support participants who want to create profitable startups by matching them with other
        participants with entrepreneurial motivation in the same teams. We provide feedback from
        mentors, awards to the best teams, and guidelines for post-hackathon support.
      </p>
      <p className='fw-bold mt-4 mb-1'>Code of conduct </p>
      <p>
        Use the statement below to explain the hackathon code of conduct. You can also read an
        example of a hackathon code of conduct{' '}
        <a
          className='fw-bold'
          href='https://devfolio.co/code-of-conduct#:~:text=We%20do%20not%20tolerate%20harassment,the%20taking%20of%20photographs%20and'
        >
          here
        </a>
        .
        <br />
        <br />
        This hackathon is an inclusive and safe event. All communication during the hackathon should
        be respectful. We do not tolerate harassment of hackathon participants in any form.
        Participants violating these rules may be sanctioned or expelled from the hackathon.
      </p>
      <p className='fw-bold mt-4 mb-1'>IP regulations </p>
      <p>
        To inform participants about the hackathon IP regulations, use the statement below on the
        hackathon`s website. You can read more about how to handle hackathon IP regulations{' '}
        <a
          className='fw-bold'
          href='https://www.europeana-space.eu/wp-content/uploads/2015/07/spa_content_faqforhackorg.pdf'
        >
          here
        </a>{' '}
        and patent filing{' '}
        <a className='fw-bold' href='https://www.wipo.int/patents/en/faq_patents.html'>
          here
        </a>
        .
        <br />
        <br />
        If you pitch an idea at the hackathon, you should abstain from disclosing key elements of
        its execution and uniqueness. The hackathon ideas are not protected by copyright, but the
        hackathon projects developed at the hackathon are. Each participant owns the copyright for
        what they developed at the hackathon. If you would like to file a patent based on your
        hackathon idea, please contact the organizers as one of the requirements to file patents is
        not to share your idea publicly.
        <br />
        <br />
        To learn more about setting up the hackathon, you may refer to the following hackathon
        guides{' '}
        <a
          className='fw-bold'
          href='https://hackathon-planning-kit.org/files/hackathon-playbook-external.pdf'
        >
          here
        </a>{' '}
        and{' '}
        <a
          className='fw-bold'
          href='https://guide.mlh.io/general-information/creating-your-hackathon-schedule'
        >
          here
        </a>{' '}
        that address multiple aspects of the event, such as finding stakeholders and sponsors, food
        and the right schedule.
        <br />
        <br />
        Make sure to provide feedback to support teams in refining and strengthening their business
        plans. You can also achieve this during the hackathon by inviting stakeholders and potential
        future users as participants, jurors or mentors.
      </p>
    </>
  );
};

export default StepOne;
