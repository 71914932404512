// dict
export const tableHeaders = {
  // Participant
  'First name': 'first_name',
  'Last name': 'last_name',
  Email: 'email',
  // 'Consent (support)': 'consent_team_formation',
  'Entrepreneurial intention': 'entrepreneurial_intention',

  // Motivation
  'Entrepreneurial motivation score': 'motivation_score',
  Feedback: 'motivation_feedback',
  'New startup': 'motivation_new_startup',
  'First version': 'motivation_first_version',
  'My startup': 'motivation_my_startup',
  'Developing skills': 'motivation_developing_skills',
  'Learning domain': 'motivation_learning_domain',

  // Idea
  'Idea type': 'idea_type',
  'Idea (name)': 'idea_name',
  'Idea (problem)': 'idea_problem',
  'Idea (solution)': 'idea_solution',
  'Target market': 'idea_audience',
  'Special help': 'idea_help',

  // Participant again
  'First timer': 'first_hackathon',
  Expectations: 'expectations',
  'Programming skills': 'skills_programming',
  'Design skills': 'skills_design',
  'Marketer skills': 'skills_marketing',
  'Business skills': 'skills_business',
  'Other skills': 'skills_other',

  // Team
  'Team status': 'team_status',
  'Team members': 'idea_team_status',
  'Team name': 'team_name',

  // Roles needed
  'Programmer needed': 'team_roles_missing_programming',
  'Designer needed': 'team_roles_missing_design',
  'Marketer needed': 'team_roles_missing_marketing',
  'Business needed': 'team_roles_missing_business',
  'Other roles needed': 'team_roles_missing_other',

  // Demographics
  Age: 'age',
  Nationality: 'nationality',
  Gender: 'gender',
  'Meal preference': 'meal_preference',
  Education: 'education',
  Employment: 'employment',
  'Consent (research)': 'consent_study',
};

// list
export const tableHeadersAsList = Object.keys(tableHeaders);

export const defaultTableFields = [
  'First name',
  'Last name',
  'Email',
  // 'Consent (support)',
  'Entrepreneurial intention',
  'Entrepreneurial motivation score',
  'Idea type',
  'Expectations',
  'Programming skills',
  'Design skills',
  'Marketer skills',
  'Business skills',
  'Other skills',
  'Team status',
];

export const filterNames = {
  'Entrepreneurial intention': 'entrepreneurial_intention',
  'First timer': 'first_hackathon__in',
  Expectations: 'expectations__isnull',
  Skills: 'skills__in',
  'Idea type': 'idea_type__in',
  'Special help': 'idea_help__isnull',
  'Team status': 'team_status__in',
  'Roles needed': 'team_roles_missing__in',
  Age: 'age__in',
  Gender: 'gender__in',
  Nationality: 'nationality__in',
  'Meal preference': 'meal_preference__in',
  Education: 'education__in',
  Employment: 'employment__in',
  'Order by': 'order_by',
  Search: 'search',

  'Motivation score lower': 'motivation_score__gte',
  'Motivation score upper': 'motivation_score__lte',

  'Team roles missing programming': 'team_roles_missing_programming',
  'Team roles missing design': 'team_roles_missing_design',
  'Team roles missing marketer': 'team_roles_missing_marketing',
  'Team roles missing business': 'team_roles_missing_business',
  'Team roles missing other': 'team_roles_missing_other',

  'Programming skills': 'skills_programming',
  'Design skills': 'skills_design',
  'Marketer skills': 'skills_marketing',
  'Business skills': 'skills_business',
  'Other skills': 'skills_other',
};

export const fieldValuesMap = {
  entrepreneurial_intention: {
    true: 'Yes',
    false: 'No',
  },
  consent_team_formation: {
    true: 'Yes',
    false: 'No',
  },
  idea_type: {
    STARTUP: 'Startup',
    GENERAL: 'General',
    UNSURE: 'Unsure',
    NO_IDEA: 'No idea',
  },
  first_hackathon: {
    true: 'Yes',
    false: 'No',
  },
  team_status: {
    TEAM_FULL: 'Team full',
    TEAM_NOT_FULL: 'Team not full',
    NO_TEAM: 'No team',
  },
  age: {
    AGE_18_24: '18-24',
    AGE_25_34: '25-34',
    AGE_35_44: '35-44',
    AGE_45_54: '45-54',
    AGE_55_64: '55-64',
    AGE_65_74: '65-74',
    AGE_75_UP: '75+',
  },
  gender: {
    M: 'Male',
    F: 'Female',
    NB: 'Non-binary',
    PNTS: 'Prefer not to say',
  },
  meal_preference: {
    ME: 'Meat eater',
    VG: 'Vegetarian',
    VE: 'Vegan',
  },
  education: {
    HS: 'High school diploma',
    BA: 'Bachelor degree',
    MS: 'Master degree',
    DOC: 'Doctorate',
    PNTS: 'Prefer not to say',
  },
  employment: {
    CEMP: 'Company employee',
    SEMP: 'Self-employed',
    UEMP: 'Unemployed',
    PNTS: 'Prefer not to say',
  },
  consent_study: {
    true: 'Yes',
    false: 'No',
  },
};

export const COLUMN_GROUPS = {
  'Motivation, team and skillset': {
    'Full name': ['First name', 'Last name'],
    Email: ['Email'],
    'Motivation score': [
      'Entrepreneurial motivation score',
      'Entrepreneurial intention',
      'Feedback',
      'Developing skills',
      'Learning domain',
    ],
    'Pitch idea': ['My startup', 'First version', 'New startup'],
    Skills: [
      'Programming skills',
      'Design skills',
      'Marketer skills',
      'Business skills',
      'Other skills',
    ],
    'First timer': ['First timer'],
    Expectations: ['Expectations'],
    'Consent (research)': ['Consent (research)'],
  },
  Team: {
    'Team status': ['Team status', 'Team members'],
    'Team name': ['Team name'],
    'Missing roles': [
      'Programmer needed',
      'Designer needed',
      'Marketer needed',
      'Business needed',
      'Other roles needed',
    ],
  },
  Idea: {
    'Idea type': ['Idea type'],
    'Idea (name)': ['Idea (name)'],
    'Idea (problem)': ['Idea (problem)', 'Special help'],
    'Idea (solution)': ['Idea (solution)'],
    'Target market': ['Target market'],
  },
  'Demographics and individual background': {
    'Meal preference': ['Meal preference'],
    Nationality: ['Nationality'],
    Age: ['Age'],
    Gender: ['Gender'],
    Education: ['Education'],
    Employment: ['Employment'],
  },
};
