import React, { useEffect } from 'react';

const Slider = (props) => {
  const inputFrom = props.filters.motivation_score__gte[0] || 0;
  const inputTo = props.filters.motivation_score__lte[0] || 5;

  const updateFilter = (event, filterName) => {
    const filterValue = event.target.value;

    if (filterName === 'motFrom') {
      props.setFilters((prevFilters) => ({
        ...prevFilters,
        motivation_score__gte: [filterValue],
      }));
    } else {
      props.setFilters((prevFilters) => ({
        ...prevFilters,
        motivation_score__lte: [filterValue],
      }));
    }
  };

  useEffect(() => {
    const display = document.getElementById(props.forId);
    const slider = document.getElementById(`slider-${props.forId}`);

    if (inputFrom > inputTo) {
      display.innerHTML = `${inputTo} - ${inputFrom}`;
      slider.style.right = `${100 - ((inputFrom - props.min) / (props.max - props.min)) * 100}%`;
      slider.style.left = `${((inputTo - props.min) / (props.max - props.min)) * 100}%`;
    } else {
      display.innerHTML = `${inputFrom} - ${inputTo}`;
      slider.style.right = `${100 - ((inputTo - props.min) / (props.max - props.min)) * 100}%`;
      slider.style.left = `${((inputFrom - props.min) / (props.max - props.min)) * 100}%`;
    }
  }, [inputFrom, inputTo, props]);

  return (
    <div>
      <div className='range-slider'>
        <span className='range-selected' id={`slider-${props.forId}`}></span>
      </div>
      <div className='range-input'>
        <input
          type='range'
          value={props.filters.motivation_score__gte[0] || 0}
          min={props.min}
          max={props.max}
          step={props.step}
          onChange={(e) => updateFilter(e, 'motFrom')}
        />
        <input
          type='range'
          value={props.filters.motivation_score__lte[0] || 5}
          min={props.min}
          max={props.max}
          step={props.step}
          onChange={(e) => updateFilter(e, 'motTo')}
        />
      </div>
    </div>
  );
};

export default Slider;
