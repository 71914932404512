import React, { Component } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';

export class FirstStep extends Component {
  continue = (e) => {
    e.preventDefault();
    const {
      values: { title, date, errMessage },
    } = this.props;
    const now = new Date();
    const formattedNow = now.toISOString().slice(0, 16);

    if (!title || title.length > 255) {
      this.props.setErrMessage('Title should be between 1 and 255 characters long');
    } else if (date < formattedNow) {
      this.props.setErrMessage('Start date should be in the future');
      console.log('Updated mes: ' + errMessage);
    } else {
      this.props.nextStep();
      this.props.setErrMessage('');
    }
  };

  render() {
    const { values, inputChange } = this.props;

    return (
      <Container>
        <Form>
          <p className='text-main-secondary'>Step 1 of 2</p>
          <Row>
            <Col className='pt-0 mt-0'>
              <h5 className='fw-bold pb-1'>Hackathon details</h5>
            </Col>
          </Row>
          <Form.Group controlId='formBasicTitle'>
            <FloatingLabel controlId='floatingInput' label='Title'>
              <Form.Control
                type='text'
                name='title'
                placeholder=''
                className='form-control-lg'
                onChange={inputChange('title')}
                value={values.title}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId='formBasicDateTime' className='mt-2'>
            <FloatingLabel controlId='floatingDate' label='Start date'>
              <Form.Control
                type='datetime-local'
                name='dateTime'
                className='form-control-lg'
                value={values.date}
                onChange={inputChange('date')}
              />
            </FloatingLabel>
          </Form.Group>
          {values.errMessage && (
            <p className='text-danger mt-3 text-center mb-0'>{values.errMessage}</p>
          )}
        </Form>
        <Row className='px-2'>
          <Button className='mt-4 btn-prim' onClick={this.continue}>
            Continue
          </Button>
        </Row>
      </Container>
    );
  }
}

export default FirstStep;
