import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { COLUMN_GROUPS, defaultTableFields, tableHeadersAsList } from '../../../data';

const TableColumns = ({
  handleFieldChange,
  tableFields,
  updateTableFields,
  handleSelectAllChange,
  selectAll,
}) => {
  return (
    <>
      <Accordion className='pb-5 mx-3 mb-5' defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
        {Object.keys(COLUMN_GROUPS).map((subGroup, i) => (
          <Accordion.Item key={i} eventKey={JSON.stringify(i)} className=''>
            <Accordion.Header className=''>{subGroup}</Accordion.Header>
            <Accordion.Body className='px-2'>
              <div className='columns-container'>
                {Object.keys(COLUMN_GROUPS[subGroup]).map((fieldName, id) => {
                  const uniqueId = `${subGroup}-${fieldName}-${id}`;
                  return (
                    <div className='form-check single-column' key={uniqueId}>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={COLUMN_GROUPS[subGroup][fieldName]}
                        id={uniqueId}
                        onChange={() => handleFieldChange(subGroup, fieldName)}
                        checked={COLUMN_GROUPS[subGroup][fieldName].some((col) =>
                          tableFields.includes(col),
                        )}
                      />
                      <label className='form-check-label' htmlFor={uniqueId}>
                        {fieldName}
                      </label>
                    </div>
                  );
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
        <div className='d-flex align-items-center justify-content-between mt-4'>
          <div className='form-check '>
            <input
              className='form-check-input'
              type='checkbox'
              value={defaultTableFields}
              id='defaultColumns'
              onChange={() => updateTableFields(defaultTableFields)}
              checked={JSON.stringify(tableFields) === JSON.stringify(defaultTableFields)}
            />
            <label className='form-check-label' htmlFor='defaultColumns'>
              Select default
            </label>
          </div>

          <div className='form-check '>
            <input
              className='form-check-input'
              type='checkbox'
              value={tableHeadersAsList}
              id='allColumns'
              onChange={handleSelectAllChange}
              checked={selectAll}
            />
            <label className='form-check-label' htmlFor='allColumns'>
              Select all
            </label>
          </div>
        </div>
      </Accordion>
    </>
  );
};

export default TableColumns;
